/*
 * Mailchimp Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.80
 * Contact: apihelp@mailchimp.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.12
 *
 * Do not edit the class manually.
 *
 */



var ApiClient = require('../ApiClient');

/**
 * Automations service.
 * @module api/AutomationsApi
 */

/**
 * Constructs a new AutomationsApi. 
 * @alias module:api/AutomationsApi
 * @class
 * @param {module:ApiClient} apiClient Optional API client implementation to use,
 * default to {@link module:ApiClient#instance} if unspecified.
 */
module.exports = function(apiClient) {
  this.apiClient = apiClient || ApiClient.instance;

  /**
   * Archive automation
   * Archiving will permanently end your automation and keep the report data. You’ll be able to replicate your archived automation, but you can’t restart it.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.archiveWithHttpInfo = function(workflowId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/actions/archive', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Archive automation
   * Archiving will permanently end your automation and keep the report data. You’ll be able to replicate your archived automation, but you can’t restart it.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.archive = function(workflowId) {
    return this.archiveWithHttpInfo(workflowId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete workflow email
   * Removes an individual classic automation workflow email. Emails from certain workflow types, including the Abandoned Cart Email (abandonedCart) and Product Retargeting Email (abandonedBrowse) Workflows, cannot be deleted.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteWorkflowEmailWithHttpInfo = function(workflowId, workflowEmailId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete workflow email
   * Removes an individual classic automation workflow email. Emails from certain workflow types, including the Abandoned Cart Email (abandonedCart) and Product Retargeting Email (abandonedBrowse) Workflows, cannot be deleted.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteWorkflowEmail = function(workflowId, workflowEmailId) {
    return this.deleteWorkflowEmailWithHttpInfo(workflowId, workflowEmailId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List automations
   * Get a summary of an account's classic automations.
   * @param {Object} opts Optional parameters
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Date} opts.beforeCreateTime Restrict the response to automations created before this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceCreateTime Restrict the response to automations created after this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.beforeStartTime Restrict the response to automations started before this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceStartTime Restrict the response to automations started after this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {module:model/String} opts.status Restrict the results to automations with the specified status.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2003} and HTTP response
   */
  this.listWithHttpInfo = function(opts) {
    opts = opts || {};
    var postBody = null;

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'before_create_time': ( opts['beforeCreateTime'] ? opts['beforeCreateTime'] : opts['before_create_time']),
      'since_create_time': ( opts['sinceCreateTime'] ? opts['sinceCreateTime'] : opts['since_create_time']),
      'before_start_time': ( opts['beforeStartTime'] ? opts['beforeStartTime'] : opts['before_start_time']),
      'since_start_time': ( opts['sinceStartTime'] ? opts['sinceStartTime'] : opts['since_start_time']),
      'status': ( opts['status'] ? opts['status'] : opts['status'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List automations
   * Get a summary of an account's classic automations.
   * @param {Object} opts Optional parameters
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Date} opts.beforeCreateTime Restrict the response to automations created before this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceCreateTime Restrict the response to automations created after this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.beforeStartTime Restrict the response to automations started before this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceStartTime Restrict the response to automations started after this time. Uses the ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {module:model/String} opts.status Restrict the results to automations with the specified status.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2003}
   */
  this.list = function(opts) {
    return this.listWithHttpInfo(opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get automation info
   * Get a summary of an individual classic automation workflow's settings and content. The `trigger_settings` object returns information for the first email in the workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AutomationWorkflow} and HTTP response
   */
  this.getWithHttpInfo = function(workflowId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get automation info
   * Get a summary of an individual classic automation workflow's settings and content. The `trigger_settings` object returns information for the first email in the workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AutomationWorkflow}
   */
  this.get = function(workflowId, opts) {
    return this.getWithHttpInfo(workflowId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List automated emails
   * Get a summary of the emails in a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AutomationEmails} and HTTP response
   */
  this.listAllWorkflowEmailsWithHttpInfo = function(workflowId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List automated emails
   * Get a summary of the emails in a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AutomationEmails}
   */
  this.listAllWorkflowEmails = function(workflowId) {
    return this.listAllWorkflowEmailsWithHttpInfo(workflowId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get workflow email info
   * Get information about an individual classic automation workflow email.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AutomationWorkflowEmail} and HTTP response
   */
  this.getWorkflowEmailWithHttpInfo = function(workflowId, workflowEmailId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get workflow email info
   * Get information about an individual classic automation workflow email.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AutomationWorkflowEmail}
   */
  this.getWorkflowEmail = function(workflowId, workflowEmailId) {
    return this.getWorkflowEmailWithHttpInfo(workflowId, workflowEmailId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List automated email subscribers
   * Get information about a classic automation email queue.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2004} and HTTP response
   */
  this.getWorkflowEmailSubscriberQueueWithHttpInfo = function(workflowId, workflowEmailId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}/queue', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List automated email subscribers
   * Get information about a classic automation email queue.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2004}
   */
  this.getWorkflowEmailSubscriberQueue = function(workflowId, workflowEmailId) {
    return this.getWorkflowEmailSubscriberQueueWithHttpInfo(workflowId, workflowEmailId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get automated email subscriber
   * Get information about a specific subscriber in a classic automation email queue.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberInAutomationQueue2} and HTTP response
   */
  this.getWorkflowEmailSubscriberWithHttpInfo = function(workflowId, workflowEmailId, subscriberHash) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}/queue/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get automated email subscriber
   * Get information about a specific subscriber in a classic automation email queue.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberInAutomationQueue2}
   */
  this.getWorkflowEmailSubscriber = function(workflowId, workflowEmailId, subscriberHash) {
    return this.getWorkflowEmailSubscriberWithHttpInfo(workflowId, workflowEmailId, subscriberHash)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List subscribers removed from workflow
   * Get information about subscribers who were removed from a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RemovedSubscribers} and HTTP response
   */
  this.listWorkflowEmailSubscribersRemovedWithHttpInfo = function(workflowId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/removed-subscribers', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List subscribers removed from workflow
   * Get information about subscribers who were removed from a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RemovedSubscribers}
   */
  this.listWorkflowEmailSubscribersRemoved = function(workflowId) {
    return this.listWorkflowEmailSubscribersRemovedWithHttpInfo(workflowId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get subscriber removed from workflow
   * Get information about a specific subscriber who was removed from a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberRemovedFromAutomationWorkflow} and HTTP response
   */
  this.getRemovedWorkflowEmailSubscriberWithHttpInfo = function(workflowId, subscriberHash) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/removed-subscribers/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get subscriber removed from workflow
   * Get information about a specific subscriber who was removed from a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberRemovedFromAutomationWorkflow}
   */
  this.getRemovedWorkflowEmailSubscriber = function(workflowId, subscriberHash) {
    return this.getRemovedWorkflowEmailSubscriberWithHttpInfo(workflowId, subscriberHash)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update workflow email
   * Update settings for a classic automation workflow email.  Only works with workflows of type: abandonedBrowse, abandonedCart, emailFollowup, or singleWelcome.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @param {module:model/UpdateInformationAboutASpecificWorkflowEmail} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AutomationWorkflowEmail} and HTTP response
   */
  this.updateWorkflowEmailWithHttpInfo = function(workflowId, workflowEmailId, body) {
    var postBody = body;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update workflow email
   * Update settings for a classic automation workflow email.  Only works with workflows of type: abandonedBrowse, abandonedCart, emailFollowup, or singleWelcome.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @param {module:model/UpdateInformationAboutASpecificWorkflowEmail} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AutomationWorkflowEmail}
   */
  this.updateWorkflowEmail = function(workflowId, workflowEmailId, body) {
    return this.updateWorkflowEmailWithHttpInfo(workflowId, workflowEmailId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add automation
   * Create a new classic automation in your Mailchimp account.
   * @param {module:model/AutomationWorkflow1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AutomationWorkflow} and HTTP response
   */
  this.createWithHttpInfo = function(body) {
    var postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add automation
   * Create a new classic automation in your Mailchimp account.
   * @param {module:model/AutomationWorkflow1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AutomationWorkflow}
   */
  this.create = function(body) {
    return this.createWithHttpInfo(body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Pause automation emails
   * Pause all emails in a specific classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.pauseAllEmailsWithHttpInfo = function(workflowId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/actions/pause-all-emails', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Pause automation emails
   * Pause all emails in a specific classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.pauseAllEmails = function(workflowId) {
    return this.pauseAllEmailsWithHttpInfo(workflowId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Start automation emails
   * Start all emails in a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.startAllEmailsWithHttpInfo = function(workflowId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/actions/start-all-emails', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Start automation emails
   * Start all emails in a classic automation workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.startAllEmails = function(workflowId) {
    return this.startAllEmailsWithHttpInfo(workflowId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Pause automated email
   * Pause an automated email.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.pauseWorkflowEmailWithHttpInfo = function(workflowId, workflowEmailId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}/actions/pause', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Pause automated email
   * Pause an automated email.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.pauseWorkflowEmail = function(workflowId, workflowEmailId) {
    return this.pauseWorkflowEmailWithHttpInfo(workflowId, workflowEmailId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Start automated email
   * Start an automated email.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.startWorkflowEmailWithHttpInfo = function(workflowId, workflowEmailId) {
    var postBody = null;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}/actions/start', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Start automated email
   * Start an automated email.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.startWorkflowEmail = function(workflowId, workflowEmailId) {
    return this.startWorkflowEmailWithHttpInfo(workflowId, workflowEmailId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add subscriber to workflow email
   * Manually add a subscriber to a workflow, bypassing the default trigger settings. You can also use this endpoint to trigger a series of automated emails in an API 3.0 workflow type.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @param {module:model/SubscriberInAutomationQueue1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberInAutomationQueue2} and HTTP response
   */
  this.addWorkflowEmailSubscriberWithHttpInfo = function(workflowId, workflowEmailId, body) {
    var postBody = body;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'workflowEmailId' is set
    if (workflowEmailId === undefined || workflowEmailId === null) {
      throw new Error("Missing the required parameter 'workflowEmailId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId,
      'workflow_email_id': workflowEmailId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/emails/{workflow_email_id}/queue', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add subscriber to workflow email
   * Manually add a subscriber to a workflow, bypassing the default trigger settings. You can also use this endpoint to trigger a series of automated emails in an API 3.0 workflow type.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {String} workflowEmailId The unique id for the Automation workflow email.
   * @param {module:model/SubscriberInAutomationQueue1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberInAutomationQueue2}
   */
  this.addWorkflowEmailSubscriber = function(workflowId, workflowEmailId, body) {
    return this.addWorkflowEmailSubscriberWithHttpInfo(workflowId, workflowEmailId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Remove subscriber from workflow
   * Remove a subscriber from a specific classic automation workflow. You can remove a subscriber at any point in an automation workflow, regardless of how many emails they've been sent from that workflow. Once they're removed, they can never be added back to the same workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {module:model/SubscriberInAutomationQueue3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberRemovedFromAutomationWorkflow} and HTTP response
   */
  this.removeWorkflowEmailSubscriberWithHttpInfo = function(workflowId, body) {
    var postBody = body;

    // verify the required parameter 'workflowId' is set
    if (workflowId === undefined || workflowId === null) {
      throw new Error("Missing the required parameter 'workflowId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'workflow_id': workflowId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/automations/{workflow_id}/removed-subscribers', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Remove subscriber from workflow
   * Remove a subscriber from a specific classic automation workflow. You can remove a subscriber at any point in an automation workflow, regardless of how many emails they've been sent from that workflow. Once they're removed, they can never be added back to the same workflow.
   * @param {String} workflowId The unique id for the Automation workflow.
   * @param {module:model/SubscriberInAutomationQueue3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberRemovedFromAutomationWorkflow}
   */
  this.removeWorkflowEmailSubscriber = function(workflowId, body) {
    return this.removeWorkflowEmailSubscriberWithHttpInfo(workflowId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }
};
