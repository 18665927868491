/*
 * Mailchimp Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.80
 * Contact: apihelp@mailchimp.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.12
 *
 * Do not edit the class manually.
 *
 */



var ApiClient = require('../ApiClient');

/**
 * Lists service.
 * @module api/ListsApi
 */

/**
 * Constructs a new ListsApi. 
 * @alias module:api/ListsApi
 * @class
 * @param {module:ApiClient} apiClient Optional API client implementation to use,
 * default to {@link module:ApiClient#instance} if unspecified.
 */
module.exports = function(apiClient) {
  this.apiClient = apiClient || ApiClient.instance;

  /**
   * Delete list
   * Delete a list from your Mailchimp account. If you delete a list, you'll lose the list history—including subscriber activity, unsubscribes, complaints, and bounces. You’ll also lose subscribers’ email addresses, unless you exported and backed up your list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteListWithHttpInfo = function(listId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete list
   * Delete a list from your Mailchimp account. If you delete a list, you'll lose the list history—including subscriber activity, unsubscribes, complaints, and bounces. You’ll also lose subscribers’ email addresses, unless you exported and backed up your list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteList = function(listId) {
    return this.deleteListWithHttpInfo(listId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete interest category
   * Delete a specific interest category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteInterestCategoryWithHttpInfo = function(listId, interestCategoryId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete interest category
   * Delete a specific interest category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteInterestCategory = function(listId, interestCategoryId) {
    return this.deleteInterestCategoryWithHttpInfo(listId, interestCategoryId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete interest in category
   * Delete interests or group names in a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {String} interestId The specific interest or 'group name'.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteInterestCategoryInterestWithHttpInfo = function(listId, interestCategoryId, interestId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    // verify the required parameter 'interestId' is set
    if (interestId === undefined || interestId === null) {
      throw new Error("Missing the required parameter 'interestId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId,
      'interest_id': interestId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}/interests/{interest_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete interest in category
   * Delete interests or group names in a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {String} interestId The specific interest or 'group name'.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteInterestCategoryInterest = function(listId, interestCategoryId, interestId) {
    return this.deleteInterestCategoryInterestWithHttpInfo(listId, interestCategoryId, interestId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Archive list member
   * Archive a list member. To permanently delete, use the delete-permanent action.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteListMemberWithHttpInfo = function(listId, subscriberHash) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Archive list member
   * Archive a list member. To permanently delete, use the delete-permanent action.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteListMember = function(listId, subscriberHash) {
    return this.deleteListMemberWithHttpInfo(listId, subscriberHash)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete note
   * Delete a specific note for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {String} noteId The id for the note.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteListMemberNoteWithHttpInfo = function(listId, subscriberHash, noteId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'noteId' is set
    if (noteId === undefined || noteId === null) {
      throw new Error("Missing the required parameter 'noteId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash,
      'note_id': noteId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/notes/{note_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete note
   * Delete a specific note for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {String} noteId The id for the note.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteListMemberNote = function(listId, subscriberHash, noteId) {
    return this.deleteListMemberNoteWithHttpInfo(listId, subscriberHash, noteId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete merge field
   * Delete a specific merge field.
   * @param {String} listId The unique ID for the list.
   * @param {String} mergeId The id for the merge field.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteListMergeFieldWithHttpInfo = function(listId, mergeId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'mergeId' is set
    if (mergeId === undefined || mergeId === null) {
      throw new Error("Missing the required parameter 'mergeId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'merge_id': mergeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/merge-fields/{merge_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete merge field
   * Delete a specific merge field.
   * @param {String} listId The unique ID for the list.
   * @param {String} mergeId The id for the merge field.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteListMergeField = function(listId, mergeId) {
    return this.deleteListMergeFieldWithHttpInfo(listId, mergeId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete segment
   * Delete a specific segment in a list.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteSegmentWithHttpInfo = function(listId, segmentId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'segmentId' is set
    if (segmentId === undefined || segmentId === null) {
      throw new Error("Missing the required parameter 'segmentId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'segment_id': segmentId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments/{segment_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete segment
   * Delete a specific segment in a list.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteSegment = function(listId, segmentId) {
    return this.deleteSegmentWithHttpInfo(listId, segmentId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Remove list member from segment
   * Remove a member from the specified static segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.removeSegmentMemberWithHttpInfo = function(listId, segmentId, subscriberHash) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'segmentId' is set
    if (segmentId === undefined || segmentId === null) {
      throw new Error("Missing the required parameter 'segmentId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'segment_id': segmentId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments/{segment_id}/members/{subscriber_hash}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Remove list member from segment
   * Remove a member from the specified static segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.removeSegmentMember = function(listId, segmentId, subscriberHash) {
    return this.removeSegmentMemberWithHttpInfo(listId, segmentId, subscriberHash)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete webhook
   * Delete a specific webhook in a list.
   * @param {String} listId The unique ID for the list.
   * @param {String} webhookId The webhook's id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteListWebhookWithHttpInfo = function(listId, webhookId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'webhookId' is set
    if (webhookId === undefined || webhookId === null) {
      throw new Error("Missing the required parameter 'webhookId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'webhook_id': webhookId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/webhooks/{webhook_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete webhook
   * Delete a specific webhook in a list.
   * @param {String} listId The unique ID for the list.
   * @param {String} webhookId The webhook's id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteListWebhook = function(listId, webhookId) {
    return this.deleteListWebhookWithHttpInfo(listId, webhookId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List member tags
   * Get the tags on a list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionOfTags} and HTTP response
   */
  this.getListMemberTagsWithHttpInfo = function(listId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/tags', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List member tags
   * Get the tags on a list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionOfTags}
   */
  this.getListMemberTags = function(listId, subscriberHash, opts) {
    return this.getListMemberTagsWithHttpInfo(listId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get lists info
   * Get information about all lists in the account.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.beforeDateCreated Restrict response to lists created before the set date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.sinceDateCreated Restrict results to lists created after the set date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeCampaignLastSent Restrict results to lists created before the last campaign send date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.sinceCampaignLastSent Restrict results to lists created after the last campaign send date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.email Restrict results to lists that include a specific subscriber's email address.
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @param {Boolean} opts.hasEcommerceStore Restrict results to lists that contain an active, connected, undeleted ecommerce store.
   * @param {Boolean} opts.includeTotalContacts Return the total_contacts field in the stats response, which contains an approximate count of all contacts in any state.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberLists} and HTTP response
   */
  this.getAllListsWithHttpInfo = function(opts) {
    opts = opts || {};
    var postBody = null;

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'before_date_created': ( opts['beforeDateCreated'] ? opts['beforeDateCreated'] : opts['before_date_created']),
      'since_date_created': ( opts['sinceDateCreated'] ? opts['sinceDateCreated'] : opts['since_date_created']),
      'before_campaign_last_sent': ( opts['beforeCampaignLastSent'] ? opts['beforeCampaignLastSent'] : opts['before_campaign_last_sent']),
      'since_campaign_last_sent': ( opts['sinceCampaignLastSent'] ? opts['sinceCampaignLastSent'] : opts['since_campaign_last_sent']),
      'email': ( opts['email'] ? opts['email'] : opts['email']),
      'sort_field': ( opts['sortField'] ? opts['sortField'] : opts['sort_field']),
      'sort_dir': ( opts['sortDir'] ? opts['sortDir'] : opts['sort_dir']),
      'has_ecommerce_store': ( opts['hasEcommerceStore'] ? opts['hasEcommerceStore'] : opts['has_ecommerce_store']),
      'include_total_contacts': ( opts['includeTotalContacts'] ? opts['includeTotalContacts'] : opts['include_total_contacts'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get lists info
   * Get information about all lists in the account.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.beforeDateCreated Restrict response to lists created before the set date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.sinceDateCreated Restrict results to lists created after the set date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeCampaignLastSent Restrict results to lists created before the last campaign send date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.sinceCampaignLastSent Restrict results to lists created after the last campaign send date. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.email Restrict results to lists that include a specific subscriber's email address.
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @param {Boolean} opts.hasEcommerceStore Restrict results to lists that contain an active, connected, undeleted ecommerce store.
   * @param {Boolean} opts.includeTotalContacts Return the total_contacts field in the stats response, which contains an approximate count of all contacts in any state.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberLists}
   */
  this.getAllLists = function(opts) {
    return this.getAllListsWithHttpInfo(opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get list info
   * Get information about a specific list in your Mailchimp account. Results include list members who have signed up but haven't confirmed their subscription yet and unsubscribed or cleaned.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Boolean} opts.includeTotalContacts Return the total_contacts field in the stats response, which contains an approximate count of all contacts in any state.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberList} and HTTP response
   */
  this.getListWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'include_total_contacts': ( opts['includeTotalContacts'] ? opts['includeTotalContacts'] : opts['include_total_contacts'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get list info
   * Get information about a specific list in your Mailchimp account. Results include list members who have signed up but haven't confirmed their subscription yet and unsubscribed or cleaned.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Boolean} opts.includeTotalContacts Return the total_contacts field in the stats response, which contains an approximate count of all contacts in any state.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberList}
   */
  this.getList = function(listId, opts) {
    return this.getListWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List abuse reports
   * Get all abuse reports for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AbuseComplaints} and HTTP response
   */
  this.getListAbuseReportsWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/abuse-reports', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List abuse reports
   * Get all abuse reports for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AbuseComplaints}
   */
  this.getListAbuseReports = function(listId, opts) {
    return this.getListAbuseReportsWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get abuse report
   * Get details about a specific abuse report.
   * @param {String} listId The unique ID for the list.
   * @param {String} reportId The id for the abuse report.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AbuseComplaint} and HTTP response
   */
  this.getListAbuseReportDetailsWithHttpInfo = function(listId, reportId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'reportId' is set
    if (reportId === undefined || reportId === null) {
      throw new Error("Missing the required parameter 'reportId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'report_id': reportId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/abuse-reports/{report_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get abuse report
   * Get details about a specific abuse report.
   * @param {String} listId The unique ID for the list.
   * @param {String} reportId The id for the abuse report.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AbuseComplaint}
   */
  this.getListAbuseReportDetails = function(listId, reportId, opts) {
    return this.getListAbuseReportDetailsWithHttpInfo(listId, reportId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List recent activity
   * Get up to the previous 180 days of daily detailed aggregated activity stats for a list, not including Automation activity.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListActivity} and HTTP response
   */
  this.getListRecentActivityWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/activity', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List recent activity
   * Get up to the previous 180 days of daily detailed aggregated activity stats for a list, not including Automation activity.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListActivity}
   */
  this.getListRecentActivity = function(listId, opts) {
    return this.getListRecentActivityWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List top email clients
   * Get a list of the top email clients based on user-agent strings.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EmailClients} and HTTP response
   */
  this.getListClientsWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/clients', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List top email clients
   * Get a list of the top email clients based on user-agent strings.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EmailClients}
   */
  this.getListClients = function(listId, opts) {
    return this.getListClientsWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List growth history data
   * Get a month-by-month summary of a specific list's growth activity.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GrowthHistory} and HTTP response
   */
  this.getListGrowthHistoryWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'sort_field': ( opts['sortField'] ? opts['sortField'] : opts['sort_field']),
      'sort_dir': ( opts['sortDir'] ? opts['sortDir'] : opts['sort_dir'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/growth-history', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List growth history data
   * Get a month-by-month summary of a specific list's growth activity.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GrowthHistory}
   */
  this.getListGrowthHistory = function(listId, opts) {
    return this.getListGrowthHistoryWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get growth history by month
   * Get a summary of a specific list's growth activity for a specific month and year.
   * @param {String} listId The unique ID for the list.
   * @param {String} month A specific month of list growth history.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GrowthHistory} and HTTP response
   */
  this.getListGrowthHistoryByMonthWithHttpInfo = function(listId, month, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'month' is set
    if (month === undefined || month === null) {
      throw new Error("Missing the required parameter 'month' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'month': month
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/growth-history/{month}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get growth history by month
   * Get a summary of a specific list's growth activity for a specific month and year.
   * @param {String} listId The unique ID for the list.
   * @param {String} month A specific month of list growth history.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GrowthHistory}
   */
  this.getListGrowthHistoryByMonth = function(listId, month, opts) {
    return this.getListGrowthHistoryByMonthWithHttpInfo(listId, month, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List interest categories
   * Get information about a list's interest categories.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.type Restrict results a type of interest group
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InterestGroupings} and HTTP response
   */
  this.getListInterestCategoriesWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'type': ( opts['type'] ? opts['type'] : opts['type'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List interest categories
   * Get information about a list's interest categories.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.type Restrict results a type of interest group
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InterestGroupings}
   */
  this.getListInterestCategories = function(listId, opts) {
    return this.getListInterestCategoriesWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get interest category info
   * Get information about a specific interest category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InterestCategory} and HTTP response
   */
  this.getInterestCategoryWithHttpInfo = function(listId, interestCategoryId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get interest category info
   * Get information about a specific interest category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InterestCategory}
   */
  this.getInterestCategory = function(listId, interestCategoryId, opts) {
    return this.getInterestCategoryWithHttpInfo(listId, interestCategoryId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List interests in category
   * Get a list of this category's interests.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Interests} and HTTP response
   */
  this.listInterestCategoryInterestsWithHttpInfo = function(listId, interestCategoryId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}/interests', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List interests in category
   * Get a list of this category's interests.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Interests}
   */
  this.listInterestCategoryInterests = function(listId, interestCategoryId, opts) {
    return this.listInterestCategoryInterestsWithHttpInfo(listId, interestCategoryId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get interest in category
   * Get interests or 'group names' for a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {String} interestId The specific interest or 'group name'.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Interest} and HTTP response
   */
  this.getInterestCategoryInterestWithHttpInfo = function(listId, interestCategoryId, interestId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    // verify the required parameter 'interestId' is set
    if (interestId === undefined || interestId === null) {
      throw new Error("Missing the required parameter 'interestId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId,
      'interest_id': interestId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}/interests/{interest_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get interest in category
   * Get interests or 'group names' for a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {String} interestId The specific interest or 'group name'.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Interest}
   */
  this.getInterestCategoryInterest = function(listId, interestCategoryId, interestId, opts) {
    return this.getInterestCategoryInterestWithHttpInfo(listId, interestCategoryId, interestId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List locations
   * Get the locations (countries) that the list's subscribers have been tagged to based on geocoding their IP address.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListLocations} and HTTP response
   */
  this.getListLocationsWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/locations', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List locations
   * Get the locations (countries) that the list's subscribers have been tagged to based on geocoding their IP address.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListLocations}
   */
  this.getListLocations = function(listId, opts) {
    return this.getListLocationsWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List members info
   * Get information about members in a specific Mailchimp list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.emailType The email type.
   * @param {module:model/String} opts.status The subscriber's status.
   * @param {String} opts.sinceTimestampOpt Restrict results to subscribers who opted-in after the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeTimestampOpt Restrict results to subscribers who opted-in before the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.sinceLastChanged Restrict results to subscribers whose information changed after the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeLastChanged Restrict results to subscribers whose information changed before the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.uniqueEmailId A unique identifier for the email address across all Mailchimp lists.
   * @param {Boolean} opts.vipOnly A filter to return only the list's VIP members. Passing `true` will restrict results to VIP list members, passing `false` will return all list members.
   * @param {String} opts.interestCategoryId The unique id for the interest category.
   * @param {String} opts.interestIds Used to filter list members by interests. Must be accompanied by interest_category_id and interest_match. The value must be a comma separated list of interest ids present for any supplied interest categories.
   * @param {module:model/String} opts.interestMatch Used to filter list members by interests. Must be accompanied by interest_category_id and interest_ids. \"any\" will match a member with any of the interest supplied, \"all\" will only match members with every interest supplied, and \"none\" will match members without any of the interest supplied.
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @param {Boolean} opts.sinceLastCampaign Filter subscribers by those subscribed/unsubscribed/pending/cleaned since last email campaign send. Member status is required to use this filter.
   * @param {String} opts.unsubscribedSince Filter subscribers by those unsubscribed since a specific date. Using any status other than unsubscribed with this filter will result in an error.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListMembers2} and HTTP response
   */
  this.getListMembersInfoWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'email_type': ( opts['emailType'] ? opts['emailType'] : opts['email_type']),
      'status': ( opts['status'] ? opts['status'] : opts['status']),
      'since_timestamp_opt': ( opts['sinceTimestampOpt'] ? opts['sinceTimestampOpt'] : opts['since_timestamp_opt']),
      'before_timestamp_opt': ( opts['beforeTimestampOpt'] ? opts['beforeTimestampOpt'] : opts['before_timestamp_opt']),
      'since_last_changed': ( opts['sinceLastChanged'] ? opts['sinceLastChanged'] : opts['since_last_changed']),
      'before_last_changed': ( opts['beforeLastChanged'] ? opts['beforeLastChanged'] : opts['before_last_changed']),
      'unique_email_id': ( opts['uniqueEmailId'] ? opts['uniqueEmailId'] : opts['unique_email_id']),
      'vip_only': ( opts['vipOnly'] ? opts['vipOnly'] : opts['vip_only']),
      'interest_category_id': ( opts['interestCategoryId'] ? opts['interestCategoryId'] : opts['interest_category_id']),
      'interest_ids': ( opts['interestIds'] ? opts['interestIds'] : opts['interest_ids']),
      'interest_match': ( opts['interestMatch'] ? opts['interestMatch'] : opts['interest_match']),
      'sort_field': ( opts['sortField'] ? opts['sortField'] : opts['sort_field']),
      'sort_dir': ( opts['sortDir'] ? opts['sortDir'] : opts['sort_dir']),
      'since_last_campaign': ( opts['sinceLastCampaign'] ? opts['sinceLastCampaign'] : opts['since_last_campaign']),
      'unsubscribed_since': ( opts['unsubscribedSince'] ? opts['unsubscribedSince'] : opts['unsubscribed_since'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List members info
   * Get information about members in a specific Mailchimp list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.emailType The email type.
   * @param {module:model/String} opts.status The subscriber's status.
   * @param {String} opts.sinceTimestampOpt Restrict results to subscribers who opted-in after the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeTimestampOpt Restrict results to subscribers who opted-in before the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.sinceLastChanged Restrict results to subscribers whose information changed after the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeLastChanged Restrict results to subscribers whose information changed before the set timeframe. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.uniqueEmailId A unique identifier for the email address across all Mailchimp lists.
   * @param {Boolean} opts.vipOnly A filter to return only the list's VIP members. Passing `true` will restrict results to VIP list members, passing `false` will return all list members.
   * @param {String} opts.interestCategoryId The unique id for the interest category.
   * @param {String} opts.interestIds Used to filter list members by interests. Must be accompanied by interest_category_id and interest_match. The value must be a comma separated list of interest ids present for any supplied interest categories.
   * @param {module:model/String} opts.interestMatch Used to filter list members by interests. Must be accompanied by interest_category_id and interest_ids. \"any\" will match a member with any of the interest supplied, \"all\" will only match members with every interest supplied, and \"none\" will match members without any of the interest supplied.
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @param {Boolean} opts.sinceLastCampaign Filter subscribers by those subscribed/unsubscribed/pending/cleaned since last email campaign send. Member status is required to use this filter.
   * @param {String} opts.unsubscribedSince Filter subscribers by those unsubscribed since a specific date. Using any status other than unsubscribed with this filter will result in an error.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListMembers2}
   */
  this.getListMembersInfo = function(listId, opts) {
    return this.getListMembersInfoWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get member info
   * Get information about a specific list member, including a currently subscribed, unsubscribed, or bounced member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListMembers2} and HTTP response
   */
  this.getListMemberWithHttpInfo = function(listId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get member info
   * Get information about a specific list member, including a currently subscribed, unsubscribed, or bounced member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListMembers2}
   */
  this.getListMember = function(listId, subscriberHash, opts) {
    return this.getListMemberWithHttpInfo(listId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * View recent activity 50
   * Get the last 50 events of a member's activity on a specific list, including opens, clicks, and unsubscribes.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.action A comma seperated list of actions to return.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MemberActivityEvents} and HTTP response
   */
  this.getListMemberActivityWithHttpInfo = function(listId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'action': this.apiClient.buildCollectionParam(opts['action'] ? opts['action'] : opts['action'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/activity', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * View recent activity 50
   * Get the last 50 events of a member's activity on a specific list, including opens, clicks, and unsubscribes.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.action A comma seperated list of actions to return.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MemberActivityEvents}
   */
  this.getListMemberActivity = function(listId, subscriberHash, opts) {
    return this.getListMemberActivityWithHttpInfo(listId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * View recent activity
   * Get a member's activity on a specific list, including opens, clicks, and unsubscribes.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.activityFilters A comma-separated list of activity filters that correspond to a set of activity types, e.g \"?activity_filters=open,bounce,click\".
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MemberActivityEvents1} and HTTP response
   */
  this.getListMemberActivityFeedWithHttpInfo = function(listId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'activity_filters': this.apiClient.buildCollectionParam(opts['activityFilters'] ? opts['activityFilters'] : opts['activity_filters'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/activity-feed', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * View recent activity
   * Get a member's activity on a specific list, including opens, clicks, and unsubscribes.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.activityFilters A comma-separated list of activity filters that correspond to a set of activity types, e.g \"?activity_filters=open,bounce,click\".
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MemberActivityEvents1}
   */
  this.getListMemberActivityFeed = function(listId, subscriberHash, opts) {
    return this.getListMemberActivityFeedWithHttpInfo(listId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List member events
   * Get events for a contact.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionOfEvents} and HTTP response
   */
  this.getListMemberEventsWithHttpInfo = function(listId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/events', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List member events
   * Get events for a contact.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionOfEvents}
   */
  this.getListMemberEvents = function(listId, subscriberHash, opts) {
    return this.getListMemberEventsWithHttpInfo(listId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List member goal events
   * Get the last 50 Goal events for a member on a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionOfMemberActivityEvents} and HTTP response
   */
  this.getListMemberGoalsWithHttpInfo = function(listId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/goals', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List member goal events
   * Get the last 50 Goal events for a member on a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionOfMemberActivityEvents}
   */
  this.getListMemberGoals = function(listId, subscriberHash, opts) {
    return this.getListMemberGoalsWithHttpInfo(listId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List recent member notes
   * Get recent notes for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {module:model/String} opts.sortField Returns notes sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionOfNotes} and HTTP response
   */
  this.getListMemberNotesWithHttpInfo = function(listId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'sort_field': ( opts['sortField'] ? opts['sortField'] : opts['sort_field']),
      'sort_dir': ( opts['sortDir'] ? opts['sortDir'] : opts['sort_dir']),
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/notes', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List recent member notes
   * Get recent notes for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {module:model/String} opts.sortField Returns notes sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionOfNotes}
   */
  this.getListMemberNotes = function(listId, subscriberHash, opts) {
    return this.getListMemberNotesWithHttpInfo(listId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get member note
   * Get a specific note for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {String} noteId The id for the note.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MemberNotes} and HTTP response
   */
  this.getListMemberNoteWithHttpInfo = function(listId, subscriberHash, noteId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'noteId' is set
    if (noteId === undefined || noteId === null) {
      throw new Error("Missing the required parameter 'noteId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash,
      'note_id': noteId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/notes/{note_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get member note
   * Get a specific note for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {String} noteId The id for the note.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MemberNotes}
   */
  this.getListMemberNote = function(listId, subscriberHash, noteId, opts) {
    return this.getListMemberNoteWithHttpInfo(listId, subscriberHash, noteId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List merge fields
   * Get a list of all merge fields for an audience.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.type The merge field type.
   * @param {Boolean} opts.required Whether it's a required merge field.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionOfMergeFields} and HTTP response
   */
  this.getListMergeFieldsWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'type': ( opts['type'] ? opts['type'] : opts['type']),
      'required': ( opts['required'] ? opts['required'] : opts['required'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/merge-fields', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List merge fields
   * Get a list of all merge fields for an audience.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.type The merge field type.
   * @param {Boolean} opts.required Whether it's a required merge field.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionOfMergeFields}
   */
  this.getListMergeFields = function(listId, opts) {
    return this.getListMergeFieldsWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get merge field
   * Get information about a specific merge field.
   * @param {String} listId The unique ID for the list.
   * @param {String} mergeId The id for the merge field.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MergeField} and HTTP response
   */
  this.getListMergeFieldWithHttpInfo = function(listId, mergeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'mergeId' is set
    if (mergeId === undefined || mergeId === null) {
      throw new Error("Missing the required parameter 'mergeId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'merge_id': mergeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/merge-fields/{merge_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get merge field
   * Get information about a specific merge field.
   * @param {String} listId The unique ID for the list.
   * @param {String} mergeId The id for the merge field.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MergeField}
   */
  this.getListMergeField = function(listId, mergeId, opts) {
    return this.getListMergeFieldWithHttpInfo(listId, mergeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get segment info
   * Get information about a specific segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Boolean} opts.includeCleaned Include cleaned members in response
   * @param {Boolean} opts.includeTransactional Include transactional members in response
   * @param {Boolean} opts.includeUnsubscribed Include unsubscribed members in response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/List7} and HTTP response
   */
  this.getSegmentWithHttpInfo = function(listId, segmentId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'segmentId' is set
    if (segmentId === undefined || segmentId === null) {
      throw new Error("Missing the required parameter 'segmentId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'segment_id': segmentId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'include_cleaned': ( opts['includeCleaned'] ? opts['includeCleaned'] : opts['include_cleaned']),
      'include_transactional': ( opts['includeTransactional'] ? opts['includeTransactional'] : opts['include_transactional']),
      'include_unsubscribed': ( opts['includeUnsubscribed'] ? opts['includeUnsubscribed'] : opts['include_unsubscribed'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments/{segment_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get segment info
   * Get information about a specific segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Boolean} opts.includeCleaned Include cleaned members in response
   * @param {Boolean} opts.includeTransactional Include transactional members in response
   * @param {Boolean} opts.includeUnsubscribed Include unsubscribed members in response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/List7}
   */
  this.getSegment = function(listId, segmentId, opts) {
    return this.getSegmentWithHttpInfo(listId, segmentId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List members in segment
   * Get information about members in a saved segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Boolean} opts.includeCleaned Include cleaned members in response
   * @param {Boolean} opts.includeTransactional Include transactional members in response
   * @param {Boolean} opts.includeUnsubscribed Include unsubscribed members in response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SegmentMembers} and HTTP response
   */
  this.getSegmentMembersListWithHttpInfo = function(listId, segmentId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'segmentId' is set
    if (segmentId === undefined || segmentId === null) {
      throw new Error("Missing the required parameter 'segmentId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'segment_id': segmentId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'include_cleaned': ( opts['includeCleaned'] ? opts['includeCleaned'] : opts['include_cleaned']),
      'include_transactional': ( opts['includeTransactional'] ? opts['includeTransactional'] : opts['include_transactional']),
      'include_unsubscribed': ( opts['includeUnsubscribed'] ? opts['includeUnsubscribed'] : opts['include_unsubscribed'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments/{segment_id}/members', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List members in segment
   * Get information about members in a saved segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {Boolean} opts.includeCleaned Include cleaned members in response
   * @param {Boolean} opts.includeTransactional Include transactional members in response
   * @param {Boolean} opts.includeUnsubscribed Include unsubscribed members in response
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SegmentMembers}
   */
  this.getSegmentMembersList = function(listId, segmentId, opts) {
    return this.getSegmentMembersListWithHttpInfo(listId, segmentId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List signup forms
   * Get signup forms for a specific list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListSignupForms} and HTTP response
   */
  this.getListSignupFormsWithHttpInfo = function(listId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/signup-forms', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List signup forms
   * Get signup forms for a specific list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListSignupForms}
   */
  this.getListSignupForms = function(listId) {
    return this.getListSignupFormsWithHttpInfo(listId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get information about all surveys for a list
   * Get information about all available surveys for a specific list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.getAllSurveysForListWithHttpInfo = function(listId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/surveys', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get information about all surveys for a list
   * Get information about all available surveys for a specific list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.getAllSurveysForList = function(listId) {
    return this.getAllSurveysForListWithHttpInfo(listId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get survey
   * Get details about a specific survey.
   * @param {String} listId The unique ID for the list.
   * @param {String} surveyId The ID of the survey.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.getSurveyWithHttpInfo = function(listId, surveyId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'surveyId' is set
    if (surveyId === undefined || surveyId === null) {
      throw new Error("Missing the required parameter 'surveyId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'survey_id': surveyId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/surveys/{survey_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get survey
   * Get details about a specific survey.
   * @param {String} listId The unique ID for the list.
   * @param {String} surveyId The ID of the survey.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.getSurvey = function(listId, surveyId) {
    return this.getSurveyWithHttpInfo(listId, surveyId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List webhooks
   * Get information about all webhooks for a specific list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListWebhooks} and HTTP response
   */
  this.getListWebhooksWithHttpInfo = function(listId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/webhooks', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List webhooks
   * Get information about all webhooks for a specific list.
   * @param {String} listId The unique ID for the list.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListWebhooks}
   */
  this.getListWebhooks = function(listId) {
    return this.getListWebhooksWithHttpInfo(listId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get webhook info
   * Get information about a specific webhook.
   * @param {String} listId The unique ID for the list.
   * @param {String} webhookId The webhook's id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListWebhooks} and HTTP response
   */
  this.getListWebhookWithHttpInfo = function(listId, webhookId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'webhookId' is set
    if (webhookId === undefined || webhookId === null) {
      throw new Error("Missing the required parameter 'webhookId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'webhook_id': webhookId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/webhooks/{webhook_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get webhook info
   * Get information about a specific webhook.
   * @param {String} listId The unique ID for the list.
   * @param {String} webhookId The webhook's id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListWebhooks}
   */
  this.getListWebhook = function(listId, webhookId) {
    return this.getListWebhookWithHttpInfo(listId, webhookId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update lists
   * Update the settings for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/SubscriberList2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberList} and HTTP response
   */
  this.updateListWithHttpInfo = function(listId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update lists
   * Update the settings for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/SubscriberList2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberList}
   */
  this.updateList = function(listId, body) {
    return this.updateListWithHttpInfo(listId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update interest category
   * Update a specific interest category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {module:model/InterestCategory2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InterestCategory} and HTTP response
   */
  this.updateInterestCategoryWithHttpInfo = function(listId, interestCategoryId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update interest category
   * Update a specific interest category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {module:model/InterestCategory2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InterestCategory}
   */
  this.updateInterestCategory = function(listId, interestCategoryId, body) {
    return this.updateInterestCategoryWithHttpInfo(listId, interestCategoryId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update interest in category
   * Update interests or 'group names' for a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {String} interestId The specific interest or 'group name'.
   * @param {module:model/Interest2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Interest} and HTTP response
   */
  this.updateInterestCategoryInterestWithHttpInfo = function(listId, interestCategoryId, interestId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    // verify the required parameter 'interestId' is set
    if (interestId === undefined || interestId === null) {
      throw new Error("Missing the required parameter 'interestId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId,
      'interest_id': interestId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}/interests/{interest_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update interest in category
   * Update interests or 'group names' for a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {String} interestId The specific interest or 'group name'.
   * @param {module:model/Interest2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Interest}
   */
  this.updateInterestCategoryInterest = function(listId, interestCategoryId, interestId, body) {
    return this.updateInterestCategoryInterestWithHttpInfo(listId, interestCategoryId, interestId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update list member
   * Update information for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {module:model/AddListMembers3} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListMembers2} and HTTP response
   */
  this.updateListMemberWithHttpInfo = function(listId, subscriberHash, body, opts) {
    opts = opts || {};
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'skip_merge_validation': ( opts['skipMergeValidation'] ? opts['skipMergeValidation'] : opts['skip_merge_validation'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update list member
   * Update information for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {module:model/AddListMembers3} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListMembers2}
   */
  this.updateListMember = function(listId, subscriberHash, body, opts) {
    return this.updateListMemberWithHttpInfo(listId, subscriberHash, body, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update note
   * Update a specific note for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {String} noteId The id for the note.
   * @param {module:model/MemberNotes2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MemberNotes} and HTTP response
   */
  this.updateListMemberNoteWithHttpInfo = function(listId, subscriberHash, noteId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'noteId' is set
    if (noteId === undefined || noteId === null) {
      throw new Error("Missing the required parameter 'noteId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash,
      'note_id': noteId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/notes/{note_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update note
   * Update a specific note for a specific list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {String} noteId The id for the note.
   * @param {module:model/MemberNotes2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MemberNotes}
   */
  this.updateListMemberNote = function(listId, subscriberHash, noteId, body) {
    return this.updateListMemberNoteWithHttpInfo(listId, subscriberHash, noteId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update merge field
   * Update a specific merge field.
   * @param {String} listId The unique ID for the list.
   * @param {String} mergeId The id for the merge field.
   * @param {module:model/MergeField2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MergeField} and HTTP response
   */
  this.updateListMergeFieldWithHttpInfo = function(listId, mergeId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'mergeId' is set
    if (mergeId === undefined || mergeId === null) {
      throw new Error("Missing the required parameter 'mergeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'merge_id': mergeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/merge-fields/{merge_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update merge field
   * Update a specific merge field.
   * @param {String} listId The unique ID for the list.
   * @param {String} mergeId The id for the merge field.
   * @param {module:model/MergeField2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MergeField}
   */
  this.updateListMergeField = function(listId, mergeId, body) {
    return this.updateListMergeFieldWithHttpInfo(listId, mergeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update segment
   * Update a specific segment in a list.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {module:model/List9} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/List7} and HTTP response
   */
  this.updateSegmentWithHttpInfo = function(listId, segmentId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'segmentId' is set
    if (segmentId === undefined || segmentId === null) {
      throw new Error("Missing the required parameter 'segmentId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'segment_id': segmentId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments/{segment_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update segment
   * Update a specific segment in a list.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {module:model/List9} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/List7}
   */
  this.updateSegment = function(listId, segmentId, body) {
    return this.updateSegmentWithHttpInfo(listId, segmentId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update webhook
   * Update the settings for an existing webhook.
   * @param {String} listId The unique ID for the list.
   * @param {String} webhookId The webhook's id.
   * @param {module:model/AddWebhook1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListWebhooks} and HTTP response
   */
  this.updateListWebhookWithHttpInfo = function(listId, webhookId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'webhookId' is set
    if (webhookId === undefined || webhookId === null) {
      throw new Error("Missing the required parameter 'webhookId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'webhook_id': webhookId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/webhooks/{webhook_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update webhook
   * Update the settings for an existing webhook.
   * @param {String} listId The unique ID for the list.
   * @param {String} webhookId The webhook's id.
   * @param {module:model/AddWebhook1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListWebhooks}
   */
  this.updateListWebhook = function(listId, webhookId, body) {
    return this.updateListWebhookWithHttpInfo(listId, webhookId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add event
   * Add an event for a list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {module:model/Events} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.createListMemberEventWithHttpInfo = function(listId, subscriberHash, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/events', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add event
   * Add an event for a list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {module:model/Events} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.createListMemberEvent = function(listId, subscriberHash, body) {
    return this.createListMemberEventWithHttpInfo(listId, subscriberHash, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add or remove member tags
   * Add or remove tags from a list member. If a tag that does not exist is passed in and set as 'active', a new tag will be created.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {module:model/MemberTags} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.updateListMemberTagsWithHttpInfo = function(listId, subscriberHash, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/tags', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add or remove member tags
   * Add or remove tags from a list member. If a tag that does not exist is passed in and set as 'active', a new tag will be created.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {module:model/MemberTags} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.updateListMemberTags = function(listId, subscriberHash, body) {
    return this.updateListMemberTagsWithHttpInfo(listId, subscriberHash, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add list
   * Create a new list in your Mailchimp account.
   * @param {module:model/SubscriberList1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubscriberList} and HTTP response
   */
  this.createListWithHttpInfo = function(body) {
    var postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add list
   * Create a new list in your Mailchimp account.
   * @param {module:model/SubscriberList1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubscriberList}
   */
  this.createList = function(body) {
    return this.createListWithHttpInfo(body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Batch subscribe or unsubscribe
   * Batch subscribe or unsubscribe list members.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/MembersToSubscribeUnsubscribeTofromAListInBatch} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @param {Boolean} opts.skipDuplicateCheck If skip_duplicate_check is true, we will ignore duplicates sent in the request when using the batch sub/unsub on the lists endpoint. The status of the first appearance in the request will be saved. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BatchUpdateListMembers} and HTTP response
   */
  this.batchListMembersWithHttpInfo = function(listId, body, opts) {
    opts = opts || {};
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'skip_merge_validation': ( opts['skipMergeValidation'] ? opts['skipMergeValidation'] : opts['skip_merge_validation']),
      'skip_duplicate_check': ( opts['skipDuplicateCheck'] ? opts['skipDuplicateCheck'] : opts['skip_duplicate_check'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Batch subscribe or unsubscribe
   * Batch subscribe or unsubscribe list members.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/MembersToSubscribeUnsubscribeTofromAListInBatch} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @param {Boolean} opts.skipDuplicateCheck If skip_duplicate_check is true, we will ignore duplicates sent in the request when using the batch sub/unsub on the lists endpoint. The status of the first appearance in the request will be saved. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BatchUpdateListMembers}
   */
  this.batchListMembers = function(listId, body, opts) {
    return this.batchListMembersWithHttpInfo(listId, body, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add interest category
   * Create a new interest category.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/InterestCategory1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InterestCategory} and HTTP response
   */
  this.createListInterestCategoryWithHttpInfo = function(listId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add interest category
   * Create a new interest category.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/InterestCategory1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InterestCategory}
   */
  this.createListInterestCategory = function(listId, body) {
    return this.createListInterestCategoryWithHttpInfo(listId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add interest in category
   * Create a new interest or 'group name' for a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {module:model/Interest1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Interest} and HTTP response
   */
  this.createInterestCategoryInterestWithHttpInfo = function(listId, interestCategoryId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'interestCategoryId' is set
    if (interestCategoryId === undefined || interestCategoryId === null) {
      throw new Error("Missing the required parameter 'interestCategoryId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'interest_category_id': interestCategoryId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/interest-categories/{interest_category_id}/interests', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add interest in category
   * Create a new interest or 'group name' for a specific category.
   * @param {String} listId The unique ID for the list.
   * @param {String} interestCategoryId The unique ID for the interest category.
   * @param {module:model/Interest1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Interest}
   */
  this.createInterestCategoryInterest = function(listId, interestCategoryId, body) {
    return this.createInterestCategoryInterestWithHttpInfo(listId, interestCategoryId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add member to list
   * Add a new member to the list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/AddListMembers1} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListMembers2} and HTTP response
   */
  this.addListMemberWithHttpInfo = function(listId, body, opts) {
    opts = opts || {};
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'skip_merge_validation': ( opts['skipMergeValidation'] ? opts['skipMergeValidation'] : opts['skip_merge_validation'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add member to list
   * Add a new member to the list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/AddListMembers1} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListMembers2}
   */
  this.addListMember = function(listId, body, opts) {
    return this.addListMemberWithHttpInfo(listId, body, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete list member
   * Delete all personally identifiable information related to a list member, and remove them from a list. This will make it impossible to re-import the list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteListMemberPermanentWithHttpInfo = function(listId, subscriberHash) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/actions/delete-permanent', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete list member
   * Delete all personally identifiable information related to a list member, and remove them from a list. This will make it impossible to re-import the list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteListMemberPermanent = function(listId, subscriberHash) {
    return this.deleteListMemberPermanentWithHttpInfo(listId, subscriberHash)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add member note
   * Add a new note for a specific subscriber.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {module:model/MemberNotes1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MemberNotes} and HTTP response
   */
  this.createListMemberNoteWithHttpInfo = function(listId, subscriberHash, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}/notes', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add member note
   * Add a new note for a specific subscriber.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {module:model/MemberNotes1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MemberNotes}
   */
  this.createListMemberNote = function(listId, subscriberHash, body) {
    return this.createListMemberNoteWithHttpInfo(listId, subscriberHash, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add merge field
   * Add a new merge field for a specific audience.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/MergeField1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MergeField} and HTTP response
   */
  this.addListMergeFieldWithHttpInfo = function(listId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/merge-fields', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add merge field
   * Add a new merge field for a specific audience.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/MergeField1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MergeField}
   */
  this.addListMergeField = function(listId, body) {
    return this.addListMergeFieldWithHttpInfo(listId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add segment
   * Create a new segment in a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/List8} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/List7} and HTTP response
   */
  this.createSegmentWithHttpInfo = function(listId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add segment
   * Create a new segment in a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/List8} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/List7}
   */
  this.createSegment = function(listId, body) {
    return this.createSegmentWithHttpInfo(listId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Batch add or remove members
   * Batch add/remove list members to static segment
   * @param {module:model/MembersToAddremoveTofromAStaticSegment} body 
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/BatchAddremoveListMembersTofromStaticSegment} and HTTP response
   */
  this.batchSegmentMembersWithHttpInfo = function(body, listId, segmentId) {
    var postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'segmentId' is set
    if (segmentId === undefined || segmentId === null) {
      throw new Error("Missing the required parameter 'segmentId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'segment_id': segmentId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments/{segment_id}', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Batch add or remove members
   * Batch add/remove list members to static segment
   * @param {module:model/MembersToAddremoveTofromAStaticSegment} body 
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/BatchAddremoveListMembersTofromStaticSegment}
   */
  this.batchSegmentMembers = function(body, listId, segmentId) {
    return this.batchSegmentMembersWithHttpInfo(body, listId, segmentId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add member to segment
   * Add a member to a static segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {module:model/Body3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListMembers1} and HTTP response
   */
  this.createSegmentMemberWithHttpInfo = function(listId, segmentId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'segmentId' is set
    if (segmentId === undefined || segmentId === null) {
      throw new Error("Missing the required parameter 'segmentId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'segment_id': segmentId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments/{segment_id}/members', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add member to segment
   * Add a member to a static segment.
   * @param {String} listId The unique ID for the list.
   * @param {String} segmentId The unique id for the segment.
   * @param {module:model/Body3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListMembers1}
   */
  this.createSegmentMember = function(listId, segmentId, body) {
    return this.createSegmentMemberWithHttpInfo(listId, segmentId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Customize signup form
   * Customize a list's default signup form.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/SignupForm1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignupForm} and HTTP response
   */
  this.updateListSignupFormWithHttpInfo = function(listId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/signup-forms', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Customize signup form
   * Customize a list's default signup form.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/SignupForm1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignupForm}
   */
  this.updateListSignupForm = function(listId, body) {
    return this.updateListSignupFormWithHttpInfo(listId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add webhook
   * Create a new webhook for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/AddWebhook} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListWebhooks} and HTTP response
   */
  this.createListWebhookWithHttpInfo = function(listId, body) {
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/webhooks', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add webhook
   * Create a new webhook for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {module:model/AddWebhook} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListWebhooks}
   */
  this.createListWebhook = function(listId, body) {
    return this.createListWebhookWithHttpInfo(listId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List segments
   * Get information about all available segments for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.type Limit results based on segment type.
   * @param {String} opts.sinceCreatedAt Restrict results to segments created after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeCreatedAt Restrict results to segments created before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Boolean} opts.includeCleaned Include cleaned members in response
   * @param {Boolean} opts.includeTransactional Include transactional members in response
   * @param {Boolean} opts.includeUnsubscribed Include unsubscribed members in response
   * @param {String} opts.sinceUpdatedAt Restrict results to segments update after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeUpdatedAt Restrict results to segments update before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CollectionOfSegments} and HTTP response
   */
  this.listSegmentsWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'type': ( opts['type'] ? opts['type'] : opts['type']),
      'since_created_at': ( opts['sinceCreatedAt'] ? opts['sinceCreatedAt'] : opts['since_created_at']),
      'before_created_at': ( opts['beforeCreatedAt'] ? opts['beforeCreatedAt'] : opts['before_created_at']),
      'include_cleaned': ( opts['includeCleaned'] ? opts['includeCleaned'] : opts['include_cleaned']),
      'include_transactional': ( opts['includeTransactional'] ? opts['includeTransactional'] : opts['include_transactional']),
      'include_unsubscribed': ( opts['includeUnsubscribed'] ? opts['includeUnsubscribed'] : opts['include_unsubscribed']),
      'since_updated_at': ( opts['sinceUpdatedAt'] ? opts['sinceUpdatedAt'] : opts['since_updated_at']),
      'before_updated_at': ( opts['beforeUpdatedAt'] ? opts['beforeUpdatedAt'] : opts['before_updated_at'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/segments', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List segments
   * Get information about all available segments for a specific list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.type Limit results based on segment type.
   * @param {String} opts.sinceCreatedAt Restrict results to segments created after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeCreatedAt Restrict results to segments created before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Boolean} opts.includeCleaned Include cleaned members in response
   * @param {Boolean} opts.includeTransactional Include transactional members in response
   * @param {Boolean} opts.includeUnsubscribed Include unsubscribed members in response
   * @param {String} opts.sinceUpdatedAt Restrict results to segments update after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.beforeUpdatedAt Restrict results to segments update before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CollectionOfSegments}
   */
  this.listSegments = function(listId, opts) {
    return this.listSegmentsWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add or update list member
   * Add or update a list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {module:model/AddListMembers2} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ListMembers2} and HTTP response
   */
  this.setListMemberWithHttpInfo = function(listId, subscriberHash, body, opts) {
    opts = opts || {};
    var postBody = body;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'skip_merge_validation': ( opts['skipMergeValidation'] ? opts['skipMergeValidation'] : opts['skip_merge_validation'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/members/{subscriber_hash}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add or update list member
   * Add or update a list member.
   * @param {String} listId The unique ID for the list.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address. This endpoint also accepts a list member's email address or contact_id.
   * @param {module:model/AddListMembers2} body 
   * @param {Object} opts Optional parameters
   * @param {Boolean} opts.skipMergeValidation If skip_merge_validation is true, member data will be accepted without merge field values, even if the merge field is usually required. This defaults to false.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ListMembers2}
   */
  this.setListMember = function(listId, subscriberHash, body, opts) {
    return this.setListMemberWithHttpInfo(listId, subscriberHash, body, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Search for tags on a list by name.
   * Search for tags on a list by name. If no name is provided, will return all tags on the list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {String} opts.name The search query used to filter tags.  The search query will be compared to each tag as a prefix, so all tags that have a name starting with this field will be returned.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagSearchResults} and HTTP response
   */
  this.tagSearchWithHttpInfo = function(listId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    var pathParams = {
      'list_id': listId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'name': ( opts['name'] ? opts['name'] : opts['name'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/tag-search', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Search for tags on a list by name.
   * Search for tags on a list by name. If no name is provided, will return all tags on the list.
   * @param {String} listId The unique ID for the list.
   * @param {Object} opts Optional parameters
   * @param {String} opts.name The search query used to filter tags.  The search query will be compared to each tag as a prefix, so all tags that have a name starting with this field will be returned.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagSearchResults}
   */
  this.tagSearch = function(listId, opts) {
    return this.tagSearchWithHttpInfo(listId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }
};
