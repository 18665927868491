/*
 * Mailchimp Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.80
 * Contact: apihelp@mailchimp.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.12
 *
 * Do not edit the class manually.
 *
 */



var ApiClient = require('../ApiClient');

/**
 * Reports service.
 * @module api/ReportsApi
 */

/**
 * Constructs a new ReportsApi. 
 * @alias module:api/ReportsApi
 * @class
 * @param {module:ApiClient} apiClient Optional API client implementation to use,
 * default to {@link module:ApiClient#instance} if unspecified.
 */
module.exports = function(apiClient) {
  this.apiClient = apiClient || ApiClient.instance;

  /**
   * List campaign reports
   * Get campaign reports.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.type The campaign type.
   * @param {Date} opts.beforeSendTime Restrict the response to campaigns sent before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceSendTime Restrict the response to campaigns sent after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignReports1} and HTTP response
   */
  this.getAllCampaignReportsWithHttpInfo = function(opts) {
    opts = opts || {};
    var postBody = null;

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'type': ( opts['type'] ? opts['type'] : opts['type']),
      'before_send_time': ( opts['beforeSendTime'] ? opts['beforeSendTime'] : opts['before_send_time']),
      'since_send_time': ( opts['sinceSendTime'] ? opts['sinceSendTime'] : opts['since_send_time'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaign reports
   * Get campaign reports.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.type The campaign type.
   * @param {Date} opts.beforeSendTime Restrict the response to campaigns sent before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceSendTime Restrict the response to campaigns sent after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignReports1}
   */
  this.getAllCampaignReports = function(opts) {
    return this.getAllCampaignReportsWithHttpInfo(opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get campaign report
   * Get report details for a specific sent campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignReport} and HTTP response
   */
  this.getCampaignReportWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get campaign report
   * Get report details for a specific sent campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignReport}
   */
  this.getCampaignReport = function(campaignId, opts) {
    return this.getCampaignReportWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List abuse reports
   * Get a list of abuse complaints for a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AbuseComplaints1} and HTTP response
   */
  this.getCampaignAbuseReportsWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/abuse-reports', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List abuse reports
   * Get a list of abuse complaints for a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AbuseComplaints1}
   */
  this.getCampaignAbuseReports = function(campaignId, opts) {
    return this.getCampaignAbuseReportsWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get abuse report
   * Get information about a specific abuse report for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} reportId The id for the abuse report.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AbuseComplaint1} and HTTP response
   */
  this.getCampaignAbuseReportWithHttpInfo = function(campaignId, reportId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'reportId' is set
    if (reportId === undefined || reportId === null) {
      throw new Error("Missing the required parameter 'reportId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'report_id': reportId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/abuse-reports/{report_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get abuse report
   * Get information about a specific abuse report for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} reportId The id for the abuse report.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AbuseComplaint1}
   */
  this.getCampaignAbuseReport = function(campaignId, reportId, opts) {
    return this.getCampaignAbuseReportWithHttpInfo(campaignId, reportId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List campaign feedback
   * Get feedback based on a campaign's statistics. Advice feedback is based on campaign stats like opens, clicks, unsubscribes, bounces, and more.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignAdviceReport} and HTTP response
   */
  this.getCampaignAdviceWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/advice', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaign feedback
   * Get feedback based on a campaign's statistics. Advice feedback is based on campaign stats like opens, clicks, unsubscribes, bounces, and more.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignAdviceReport}
   */
  this.getCampaignAdvice = function(campaignId, opts) {
    return this.getCampaignAdviceWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List campaign details
   * Get information about clicks on specific links in your Mailchimp campaigns.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClickDetailReport} and HTTP response
   */
  this.getCampaignClickDetailsWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/click-details', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaign details
   * Get information about clicks on specific links in your Mailchimp campaigns.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClickDetailReport}
   */
  this.getCampaignClickDetails = function(campaignId, opts) {
    return this.getCampaignClickDetailsWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get campaign link details
   * Get click details for a specific link in a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} linkId The id for the link.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClickDetailReport} and HTTP response
   */
  this.getCampaignClickDetailsForLinkWithHttpInfo = function(campaignId, linkId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'linkId' is set
    if (linkId === undefined || linkId === null) {
      throw new Error("Missing the required parameter 'linkId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'link_id': linkId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/click-details/{link_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get campaign link details
   * Get click details for a specific link in a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} linkId The id for the link.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClickDetailReport}
   */
  this.getCampaignClickDetailsForLink = function(campaignId, linkId, opts) {
    return this.getCampaignClickDetailsForLinkWithHttpInfo(campaignId, linkId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List clicked link subscribers
   * Get information about list members who clicked on a specific link in a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} linkId The id for the link.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClickDetailMembers} and HTTP response
   */
  this.getSubscribersInfoWithHttpInfo = function(campaignId, linkId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'linkId' is set
    if (linkId === undefined || linkId === null) {
      throw new Error("Missing the required parameter 'linkId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'link_id': linkId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/click-details/{link_id}/members', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List clicked link subscribers
   * Get information about list members who clicked on a specific link in a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} linkId The id for the link.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClickDetailMembers}
   */
  this.getSubscribersInfo = function(campaignId, linkId, opts) {
    return this.getSubscribersInfoWithHttpInfo(campaignId, linkId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get clicked link subscriber
   * Get information about a specific subscriber who clicked a link in a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} linkId The id for the link.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClickDetailMember} and HTTP response
   */
  this.getSubscriberInfoWithHttpInfo = function(campaignId, linkId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'linkId' is set
    if (linkId === undefined || linkId === null) {
      throw new Error("Missing the required parameter 'linkId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'link_id': linkId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/click-details/{link_id}/members/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get clicked link subscriber
   * Get information about a specific subscriber who clicked a link in a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} linkId The id for the link.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClickDetailMember}
   */
  this.getSubscriberInfo = function(campaignId, linkId, subscriberHash, opts) {
    return this.getSubscriberInfoWithHttpInfo(campaignId, linkId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List domain performance stats
   * Get statistics for the top-performing email domains in a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DomainPerformance} and HTTP response
   */
  this.getDomainPerformanceForCampaignWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/domain-performance', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List domain performance stats
   * Get statistics for the top-performing email domains in a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DomainPerformance}
   */
  this.getDomainPerformanceForCampaign = function(campaignId, opts) {
    return this.getDomainPerformanceForCampaignWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List campaign product activity
   * Get breakdown of product activity for a campaign
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2007} and HTTP response
   */
  this.getEcommerceProductActivityForCampaignWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'sort_field': ( opts['sortField'] ? opts['sortField'] : opts['sort_field'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/ecommerce-product-activity', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaign product activity
   * Get breakdown of product activity for a campaign
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2007}
   */
  this.getEcommerceProductActivityForCampaign = function(campaignId, opts) {
    return this.getEcommerceProductActivityForCampaignWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List EepURL activity
   * Get a summary of social activity for the campaign, tracked by EepURL.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EepurlActivity} and HTTP response
   */
  this.getEepurlActivityForCampaignWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/eepurl', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List EepURL activity
   * Get a summary of social activity for the campaign, tracked by EepURL.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EepurlActivity}
   */
  this.getEepurlActivityForCampaign = function(campaignId, opts) {
    return this.getEepurlActivityForCampaignWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List email activity
   * Get a list of member's subscriber activity in a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.since Restrict results to email activity events that occur after a specific time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EmailActivity} and HTTP response
   */
  this.getEmailActivityForCampaignWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'since': ( opts['since'] ? opts['since'] : opts['since'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/email-activity', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List email activity
   * Get a list of member's subscriber activity in a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.since Restrict results to email activity events that occur after a specific time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EmailActivity}
   */
  this.getEmailActivityForCampaign = function(campaignId, opts) {
    return this.getEmailActivityForCampaignWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get subscriber email activity
   * Get a specific list member's activity in a campaign including opens, clicks, and bounces.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {String} opts.since Restrict results to email activity events that occur after a specific time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EmailActivity} and HTTP response
   */
  this.getEmailActivityForSubscriberWithHttpInfo = function(campaignId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'since': ( opts['since'] ? opts['since'] : opts['since'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/email-activity/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get subscriber email activity
   * Get a specific list member's activity in a campaign including opens, clicks, and bounces.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {String} opts.since Restrict results to email activity events that occur after a specific time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EmailActivity}
   */
  this.getEmailActivityForSubscriber = function(campaignId, subscriberHash, opts) {
    return this.getEmailActivityForSubscriberWithHttpInfo(campaignId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List top open activities
   * Get top open locations for a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OpenLocations} and HTTP response
   */
  this.getLocationsForCampaignWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/locations', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List top open activities
   * Get top open locations for a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OpenLocations}
   */
  this.getLocationsForCampaign = function(campaignId, opts) {
    return this.getLocationsForCampaignWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List campaign open details
   * Get detailed information about any campaign emails that were opened by a list member.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.since Restrict results to campaign open events that occur after a specific time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OpenDetailReport} and HTTP response
   */
  this.getCampaignOpenDetailsWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'since': ( opts['since'] ? opts['since'] : opts['since'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/open-details', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaign open details
   * Get detailed information about any campaign emails that were opened by a list member.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.since Restrict results to campaign open events that occur after a specific time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OpenDetailReport}
   */
  this.getCampaignOpenDetails = function(campaignId, opts) {
    return this.getCampaignOpenDetailsWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get opened campaign subscriber
   * Get information about a specific subscriber who opened a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OpenActivity} and HTTP response
   */
  this.getSubscriberInfoForOpenedCampaignWithHttpInfo = function(campaignId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/open-details/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get opened campaign subscriber
   * Get information about a specific subscriber who opened a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OpenActivity}
   */
  this.getSubscriberInfoForOpenedCampaign = function(campaignId, subscriberHash, opts) {
    return this.getSubscriberInfoForOpenedCampaignWithHttpInfo(campaignId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List campaign recipients
   * Get information about campaign recipients.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SentTo} and HTTP response
   */
  this.getCampaignRecipientsWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/sent-to', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaign recipients
   * Get information about campaign recipients.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SentTo}
   */
  this.getCampaignRecipients = function(campaignId, opts) {
    return this.getCampaignRecipientsWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get campaign recipient info
   * Get information about a specific campaign recipient.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SentTo} and HTTP response
   */
  this.getCampaignRecipientWithHttpInfo = function(campaignId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/sent-to/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get campaign recipient info
   * Get information about a specific campaign recipient.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SentTo}
   */
  this.getCampaignRecipient = function(campaignId, subscriberHash, opts) {
    return this.getCampaignRecipientWithHttpInfo(campaignId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List child campaign reports
   * Get a list of reports with child campaigns for a specific parent campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignSubReports} and HTTP response
   */
  this.getSubReportsForCampaignWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/sub-reports', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List child campaign reports
   * Get a list of reports with child campaigns for a specific parent campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignSubReports}
   */
  this.getSubReportsForCampaign = function(campaignId, opts) {
    return this.getSubReportsForCampaignWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List unsubscribed members
   * Get information about members who have unsubscribed from a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Unsubscribes} and HTTP response
   */
  this.getUnsubscribedListForCampaignWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/unsubscribed', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List unsubscribed members
   * Get information about members who have unsubscribed from a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Unsubscribes}
   */
  this.getUnsubscribedListForCampaign = function(campaignId, opts) {
    return this.getUnsubscribedListForCampaignWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get unsubscribed member
   * Get information about a specific list member who unsubscribed from a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Unsubscribes} and HTTP response
   */
  this.getUnsubscribedListMemberWithHttpInfo = function(campaignId, subscriberHash, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'subscriberHash' is set
    if (subscriberHash === undefined || subscriberHash === null) {
      throw new Error("Missing the required parameter 'subscriberHash' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'subscriber_hash': subscriberHash
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/reports/{campaign_id}/unsubscribed/{subscriber_hash}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get unsubscribed member
   * Get information about a specific list member who unsubscribed from a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} subscriberHash The MD5 hash of the lowercase version of the list member's email address.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Unsubscribes}
   */
  this.getUnsubscribedListMember = function(campaignId, subscriberHash, opts) {
    return this.getUnsubscribedListMemberWithHttpInfo(campaignId, subscriberHash, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }
};
