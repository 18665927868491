/*
 * Mailchimp Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.80
 * Contact: apihelp@mailchimp.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.12
 *
 * Do not edit the class manually.
 *
 */



var ApiClient = require('../ApiClient');

/**
 * Surveys service.
 * @module api/SurveysApi
 */

/**
 * Constructs a new SurveysApi. 
 * @alias module:api/SurveysApi
 * @class
 * @param {module:ApiClient} apiClient Optional API client implementation to use,
 * default to {@link module:ApiClient#instance} if unspecified.
 */
module.exports = function(apiClient) {
  this.apiClient = apiClient || ApiClient.instance;

  /**
   * Publish a Survey
   * Publish a survey that is in draft, unpublished, or has been previously published and edited.
   * @param {String} listId The unique ID for the list.
   * @param {String} surveyId The ID of the survey.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.publishSurveyWithHttpInfo = function(listId, surveyId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'surveyId' is set
    if (surveyId === undefined || surveyId === null) {
      throw new Error("Missing the required parameter 'surveyId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'survey_id': surveyId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/surveys/{survey_id}/actions/publish', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Publish a Survey
   * Publish a survey that is in draft, unpublished, or has been previously published and edited.
   * @param {String} listId The unique ID for the list.
   * @param {String} surveyId The ID of the survey.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.publishSurvey = function(listId, surveyId) {
    return this.publishSurveyWithHttpInfo(listId, surveyId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Unpublish a Survey
   * Unpublish a survey that has been published.
   * @param {String} listId The unique ID for the list.
   * @param {String} surveyId The ID of the survey.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.unpublishSurveyWithHttpInfo = function(listId, surveyId) {
    var postBody = null;

    // verify the required parameter 'listId' is set
    if (listId === undefined || listId === null) {
      throw new Error("Missing the required parameter 'listId' when calling ");
    }

    // verify the required parameter 'surveyId' is set
    if (surveyId === undefined || surveyId === null) {
      throw new Error("Missing the required parameter 'surveyId' when calling ");
    }

    var pathParams = {
      'list_id': listId,
      'survey_id': surveyId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/lists/{list_id}/surveys/{survey_id}/actions/unpublish', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Unpublish a Survey
   * Unpublish a survey that has been published.
   * @param {String} listId The unique ID for the list.
   * @param {String} surveyId The ID of the survey.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.unpublishSurvey = function(listId, surveyId) {
    return this.unpublishSurveyWithHttpInfo(listId, surveyId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }
};
