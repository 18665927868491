/*
 * Mailchimp Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.80
 * Contact: apihelp@mailchimp.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.12
 *
 * Do not edit the class manually.
 *
 */



var ApiClient = require('../ApiClient');

/**
 * Ecommerce service.
 * @module api/EcommerceApi
 */

/**
 * Constructs a new EcommerceApi. 
 * @alias module:api/EcommerceApi
 * @class
 * @param {module:ApiClient} apiClient Optional API client implementation to use,
 * default to {@link module:ApiClient#instance} if unspecified.
 */
module.exports = function(apiClient) {
  this.apiClient = apiClient || ApiClient.instance;

  /**
   * Delete store
   * Delete a store. Deleting a store will also delete any associated subresources, including Customers, Orders, Products, and Carts.
   * @param {String} storeId The store id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Object} and HTTP response
   */
  this.deleteStoreWithHttpInfo = function(storeId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete store
   * Delete a store. Deleting a store will also delete any associated subresources, including Customers, Orders, Products, and Carts.
   * @param {String} storeId The store id.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Object}
   */
  this.deleteStore = function(storeId) {
    return this.deleteStoreWithHttpInfo(storeId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete cart
   * Delete a cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteStoreCartWithHttpInfo = function(storeId, cartId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete cart
   * Delete a cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteStoreCart = function(storeId, cartId) {
    return this.deleteStoreCartWithHttpInfo(storeId, cartId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete cart line item
   * Delete a specific cart line item.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {String} lineId The id for the line item of a cart.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteCartLineItemWithHttpInfo = function(storeId, cartId, lineId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    // verify the required parameter 'lineId' is set
    if (lineId === undefined || lineId === null) {
      throw new Error("Missing the required parameter 'lineId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId,
      'line_id': lineId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}/lines/{line_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete cart line item
   * Delete a specific cart line item.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {String} lineId The id for the line item of a cart.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteCartLineItem = function(storeId, cartId, lineId) {
    return this.deleteCartLineItemWithHttpInfo(storeId, cartId, lineId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete customer
   * Delete a customer from a store.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteStoreCustomerWithHttpInfo = function(storeId, customerId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'customer_id': customerId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/customers/{customer_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete customer
   * Delete a customer from a store.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteStoreCustomer = function(storeId, customerId) {
    return this.deleteStoreCustomerWithHttpInfo(storeId, customerId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete order
   * Delete an order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteOrderWithHttpInfo = function(storeId, orderId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete order
   * Delete an order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteOrder = function(storeId, orderId) {
    return this.deleteOrderWithHttpInfo(storeId, orderId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete order line item
   * Delete a specific order line item.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {String} lineId The id for the line item of an order.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteOrderLineItemWithHttpInfo = function(storeId, orderId, lineId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    // verify the required parameter 'lineId' is set
    if (lineId === undefined || lineId === null) {
      throw new Error("Missing the required parameter 'lineId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId,
      'line_id': lineId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}/lines/{line_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete order line item
   * Delete a specific order line item.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {String} lineId The id for the line item of an order.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteOrderLineItem = function(storeId, orderId, lineId) {
    return this.deleteOrderLineItemWithHttpInfo(storeId, orderId, lineId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete product
   * Delete a product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteStoreProductWithHttpInfo = function(storeId, productId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete product
   * Delete a product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteStoreProduct = function(storeId, productId) {
    return this.deleteStoreProductWithHttpInfo(storeId, productId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete product image
   * Delete a product image.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} imageId The id for the product image.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteProductImageWithHttpInfo = function(storeId, productId, imageId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'imageId' is set
    if (imageId === undefined || imageId === null) {
      throw new Error("Missing the required parameter 'imageId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId,
      'image_id': imageId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/images/{image_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete product image
   * Delete a product image.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} imageId The id for the product image.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteProductImage = function(storeId, productId, imageId) {
    return this.deleteProductImageWithHttpInfo(storeId, productId, imageId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete product variant
   * Delete a product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteProductVariantWithHttpInfo = function(storeId, productId, variantId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'variantId' is set
    if (variantId === undefined || variantId === null) {
      throw new Error("Missing the required parameter 'variantId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId,
      'variant_id': variantId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/variants/{variant_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete product variant
   * Delete a product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteProductVariant = function(storeId, productId, variantId) {
    return this.deleteProductVariantWithHttpInfo(storeId, productId, variantId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete promo code
   * Delete a promo code from a store.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} promoCodeId The id for the promo code of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deletePromoCodeWithHttpInfo = function(storeId, promoRuleId, promoCodeId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    // verify the required parameter 'promoCodeId' is set
    if (promoCodeId === undefined || promoCodeId === null) {
      throw new Error("Missing the required parameter 'promoCodeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'promo_rule_id': promoRuleId,
      'promo_code_id': promoCodeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}/promo-codes/{promo_code_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete promo code
   * Delete a promo code from a store.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} promoCodeId The id for the promo code of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deletePromoCode = function(storeId, promoRuleId, promoCodeId) {
    return this.deletePromoCodeWithHttpInfo(storeId, promoRuleId, promoCodeId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete promo rule
   * Delete a promo rule from a store.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deletePromoRuleWithHttpInfo = function(storeId, promoRuleId) {
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'promo_rule_id': promoRuleId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete promo rule
   * Delete a promo rule from a store.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deletePromoRule = function(storeId, promoRuleId) {
    return this.deletePromoRuleWithHttpInfo(storeId, promoRuleId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List account orders
   * Get information about an account's orders.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.campaignId Restrict results to orders with a specific `campaign_id` value.
   * @param {String} opts.outreachId Restrict results to orders with a specific `outreach_id` value.
   * @param {String} opts.customerId Restrict results to orders made by a specific customer.
   * @param {Boolean} opts.hasOutreach Restrict results to orders that have an outreach attached. For example, an email campaign or Facebook ad.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Orders} and HTTP response
   */
  this.ordersWithHttpInfo = function(opts) {
    opts = opts || {};
    var postBody = null;

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'campaign_id': ( opts['campaignId'] ? opts['campaignId'] : opts['campaign_id']),
      'outreach_id': ( opts['outreachId'] ? opts['outreachId'] : opts['outreach_id']),
      'customer_id': ( opts['customerId'] ? opts['customerId'] : opts['customer_id']),
      'has_outreach': ( opts['hasOutreach'] ? opts['hasOutreach'] : opts['has_outreach'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/orders', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List account orders
   * Get information about an account's orders.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.campaignId Restrict results to orders with a specific `campaign_id` value.
   * @param {String} opts.outreachId Restrict results to orders with a specific `outreach_id` value.
   * @param {String} opts.customerId Restrict results to orders made by a specific customer.
   * @param {Boolean} opts.hasOutreach Restrict results to orders that have an outreach attached. For example, an email campaign or Facebook ad.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Orders}
   */
  this.orders = function(opts) {
    return this.ordersWithHttpInfo(opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List stores
   * Get information about all stores in the account.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceStores} and HTTP response
   */
  this.storesWithHttpInfo = function(opts) {
    opts = opts || {};
    var postBody = null;

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List stores
   * Get information about all stores in the account.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceStores}
   */
  this.stores = function(opts) {
    return this.storesWithHttpInfo(opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get store info
   * Get information about a specific store.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceStore} and HTTP response
   */
  this.getStoreWithHttpInfo = function(storeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get store info
   * Get information about a specific store.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceStore}
   */
  this.getStore = function(storeId, opts) {
    return this.getStoreWithHttpInfo(storeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List carts
   * Get information about a store's carts.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Carts} and HTTP response
   */
  this.getStoreCartsWithHttpInfo = function(storeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List carts
   * Get information about a store's carts.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Carts}
   */
  this.getStoreCarts = function(storeId, opts) {
    return this.getStoreCartsWithHttpInfo(storeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get cart info
   * Get information about a specific cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCart} and HTTP response
   */
  this.getStoreCartWithHttpInfo = function(storeId, cartId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get cart info
   * Get information about a specific cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCart}
   */
  this.getStoreCart = function(storeId, cartId, opts) {
    return this.getStoreCartWithHttpInfo(storeId, cartId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List cart line items
   * Get information about a cart's line items.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CartLines} and HTTP response
   */
  this.getAllCartLineItemsWithHttpInfo = function(storeId, cartId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}/lines', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List cart line items
   * Get information about a cart's line items.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CartLines}
   */
  this.getAllCartLineItems = function(storeId, cartId, opts) {
    return this.getAllCartLineItemsWithHttpInfo(storeId, cartId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get cart line item
   * Get information about a specific cart line item.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {String} lineId The id for the line item of a cart.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCartLineItem} and HTTP response
   */
  this.getCartLineItemWithHttpInfo = function(storeId, cartId, lineId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    // verify the required parameter 'lineId' is set
    if (lineId === undefined || lineId === null) {
      throw new Error("Missing the required parameter 'lineId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId,
      'line_id': lineId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}/lines/{line_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get cart line item
   * Get information about a specific cart line item.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {String} lineId The id for the line item of a cart.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCartLineItem}
   */
  this.getCartLineItem = function(storeId, cartId, lineId, opts) {
    return this.getCartLineItemWithHttpInfo(storeId, cartId, lineId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List customers
   * Get information about a store's customers.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.emailAddress Restrict the response to customers with the email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Customers} and HTTP response
   */
  this.getAllStoreCustomersWithHttpInfo = function(storeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'email_address': ( opts['emailAddress'] ? opts['emailAddress'] : opts['email_address'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/customers', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List customers
   * Get information about a store's customers.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.emailAddress Restrict the response to customers with the email address.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Customers}
   */
  this.getAllStoreCustomers = function(storeId, opts) {
    return this.getAllStoreCustomersWithHttpInfo(storeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get customer info
   * Get information about a specific customer.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCustomer} and HTTP response
   */
  this.getStoreCustomerWithHttpInfo = function(storeId, customerId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'customer_id': customerId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/customers/{customer_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get customer info
   * Get information about a specific customer.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCustomer}
   */
  this.getStoreCustomer = function(storeId, customerId, opts) {
    return this.getStoreCustomerWithHttpInfo(storeId, customerId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List orders
   * Get information about a store's orders.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.customerId Restrict results to orders made by a specific customer.
   * @param {Boolean} opts.hasOutreach Restrict results to orders that have an outreach attached. For example, an email campaign or Facebook ad.
   * @param {String} opts.campaignId Restrict results to orders with a specific `campaign_id` value.
   * @param {String} opts.outreachId Restrict results to orders with a specific `outreach_id` value.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Orders1} and HTTP response
   */
  this.getStoreOrdersWithHttpInfo = function(storeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'customer_id': ( opts['customerId'] ? opts['customerId'] : opts['customer_id']),
      'has_outreach': ( opts['hasOutreach'] ? opts['hasOutreach'] : opts['has_outreach']),
      'campaign_id': ( opts['campaignId'] ? opts['campaignId'] : opts['campaign_id']),
      'outreach_id': ( opts['outreachId'] ? opts['outreachId'] : opts['outreach_id'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List orders
   * Get information about a store's orders.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {String} opts.customerId Restrict results to orders made by a specific customer.
   * @param {Boolean} opts.hasOutreach Restrict results to orders that have an outreach attached. For example, an email campaign or Facebook ad.
   * @param {String} opts.campaignId Restrict results to orders with a specific `campaign_id` value.
   * @param {String} opts.outreachId Restrict results to orders with a specific `outreach_id` value.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Orders1}
   */
  this.getStoreOrders = function(storeId, opts) {
    return this.getStoreOrdersWithHttpInfo(storeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get order info
   * Get information about a specific order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceOrder} and HTTP response
   */
  this.getOrderWithHttpInfo = function(storeId, orderId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get order info
   * Get information about a specific order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceOrder}
   */
  this.getOrder = function(storeId, orderId, opts) {
    return this.getOrderWithHttpInfo(storeId, orderId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List order line items
   * Get information about an order's line items.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderLines} and HTTP response
   */
  this.getAllOrderLineItemsWithHttpInfo = function(storeId, orderId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}/lines', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List order line items
   * Get information about an order's line items.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderLines}
   */
  this.getAllOrderLineItems = function(storeId, orderId, opts) {
    return this.getAllOrderLineItemsWithHttpInfo(storeId, orderId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get order line item
   * Get information about a specific order line item.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {String} lineId The id for the line item of an order.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceOrderLineItem} and HTTP response
   */
  this.getOrderLineItemWithHttpInfo = function(storeId, orderId, lineId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    // verify the required parameter 'lineId' is set
    if (lineId === undefined || lineId === null) {
      throw new Error("Missing the required parameter 'lineId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId,
      'line_id': lineId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}/lines/{line_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get order line item
   * Get information about a specific order line item.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {String} lineId The id for the line item of an order.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceOrderLineItem}
   */
  this.getOrderLineItem = function(storeId, orderId, lineId, opts) {
    return this.getOrderLineItemWithHttpInfo(storeId, orderId, lineId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List product
   * Get information about a store's products.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Products} and HTTP response
   */
  this.getAllStoreProductsWithHttpInfo = function(storeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List product
   * Get information about a store's products.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Products}
   */
  this.getAllStoreProducts = function(storeId, opts) {
    return this.getAllStoreProductsWithHttpInfo(storeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get product info
   * Get information about a specific product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProduct} and HTTP response
   */
  this.getStoreProductWithHttpInfo = function(storeId, productId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get product info
   * Get information about a specific product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProduct}
   */
  this.getStoreProduct = function(storeId, productId, opts) {
    return this.getStoreProductWithHttpInfo(storeId, productId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List product images
   * Get information about a product's images.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductImages} and HTTP response
   */
  this.getProductImagesWithHttpInfo = function(storeId, productId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/images', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List product images
   * Get information about a product's images.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductImages}
   */
  this.getProductImages = function(storeId, productId, opts) {
    return this.getProductImagesWithHttpInfo(storeId, productId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get product image info
   * Get information about a specific product image.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} imageId The id for the product image.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductImage} and HTTP response
   */
  this.getProductImageWithHttpInfo = function(storeId, productId, imageId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'imageId' is set
    if (imageId === undefined || imageId === null) {
      throw new Error("Missing the required parameter 'imageId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId,
      'image_id': imageId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/images/{image_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get product image info
   * Get information about a specific product image.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} imageId The id for the product image.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductImage}
   */
  this.getProductImage = function(storeId, productId, imageId, opts) {
    return this.getProductImageWithHttpInfo(storeId, productId, imageId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List product variants
   * Get information about a product's variants.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductVariants} and HTTP response
   */
  this.getProductVariantsWithHttpInfo = function(storeId, productId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/variants', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List product variants
   * Get information about a product's variants.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductVariants}
   */
  this.getProductVariants = function(storeId, productId, opts) {
    return this.getProductVariantsWithHttpInfo(storeId, productId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get product variant info
   * Get information about a specific product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductVariant} and HTTP response
   */
  this.getProductVariantWithHttpInfo = function(storeId, productId, variantId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'variantId' is set
    if (variantId === undefined || variantId === null) {
      throw new Error("Missing the required parameter 'variantId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId,
      'variant_id': variantId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/variants/{variant_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get product variant info
   * Get information about a specific product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductVariant}
   */
  this.getProductVariant = function(storeId, productId, variantId, opts) {
    return this.getProductVariantWithHttpInfo(storeId, productId, variantId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List promo codes
   * Get information about a store's promo codes.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PromoCodes} and HTTP response
   */
  this.getPromoCodesWithHttpInfo = function(promoRuleId, storeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'promo_rule_id': promoRuleId,
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}/promo-codes', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List promo codes
   * Get information about a store's promo codes.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PromoCodes}
   */
  this.getPromoCodes = function(promoRuleId, storeId, opts) {
    return this.getPromoCodesWithHttpInfo(promoRuleId, storeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get promo code
   * Get information about a specific promo code.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} promoCodeId The id for the promo code of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommercePromoCode} and HTTP response
   */
  this.getPromoCodeWithHttpInfo = function(storeId, promoRuleId, promoCodeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    // verify the required parameter 'promoCodeId' is set
    if (promoCodeId === undefined || promoCodeId === null) {
      throw new Error("Missing the required parameter 'promoCodeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'promo_rule_id': promoRuleId,
      'promo_code_id': promoCodeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}/promo-codes/{promo_code_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get promo code
   * Get information about a specific promo code.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} promoCodeId The id for the promo code of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommercePromoCode}
   */
  this.getPromoCode = function(storeId, promoRuleId, promoCodeId, opts) {
    return this.getPromoCodeWithHttpInfo(storeId, promoRuleId, promoCodeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List promo rules
   * Get information about a store's promo rules.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PromoRules} and HTTP response
   */
  this.listPromoRulesWithHttpInfo = function(storeId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List promo rules
   * Get information about a store's promo rules.
   * @param {String} storeId The store id.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PromoRules}
   */
  this.listPromoRules = function(storeId, opts) {
    return this.listPromoRulesWithHttpInfo(storeId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get promo rule
   * Get information about a specific promo rule.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommercePromoRule} and HTTP response
   */
  this.getPromoRuleWithHttpInfo = function(storeId, promoRuleId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'promo_rule_id': promoRuleId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get promo rule
   * Get information about a specific promo rule.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommercePromoRule}
   */
  this.getPromoRule = function(storeId, promoRuleId, opts) {
    return this.getPromoRuleWithHttpInfo(storeId, promoRuleId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update store
   * Update a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceStore2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceStore} and HTTP response
   */
  this.updateStoreWithHttpInfo = function(storeId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update store
   * Update a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceStore2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceStore}
   */
  this.updateStore = function(storeId, body) {
    return this.updateStoreWithHttpInfo(storeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update cart
   * Update a specific cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {module:model/EcommerceCart2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCart} and HTTP response
   */
  this.updateStoreCartWithHttpInfo = function(storeId, cartId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update cart
   * Update a specific cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {module:model/EcommerceCart2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCart}
   */
  this.updateStoreCart = function(storeId, cartId, body) {
    return this.updateStoreCartWithHttpInfo(storeId, cartId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update cart line item
   * Update a specific cart line item.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {String} lineId The id for the line item of a cart.
   * @param {module:model/EcommerceCartLineItem4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCartLineItem} and HTTP response
   */
  this.updateCartLineItemWithHttpInfo = function(storeId, cartId, lineId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    // verify the required parameter 'lineId' is set
    if (lineId === undefined || lineId === null) {
      throw new Error("Missing the required parameter 'lineId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId,
      'line_id': lineId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}/lines/{line_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update cart line item
   * Update a specific cart line item.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {String} lineId The id for the line item of a cart.
   * @param {module:model/EcommerceCartLineItem4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCartLineItem}
   */
  this.updateCartLineItem = function(storeId, cartId, lineId, body) {
    return this.updateCartLineItemWithHttpInfo(storeId, cartId, lineId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update customer
   * Update a customer.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @param {module:model/EcommerceCustomer5} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCustomer} and HTTP response
   */
  this.updateStoreCustomerWithHttpInfo = function(storeId, customerId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'customer_id': customerId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/customers/{customer_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update customer
   * Update a customer.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @param {module:model/EcommerceCustomer5} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCustomer}
   */
  this.updateStoreCustomer = function(storeId, customerId, body) {
    return this.updateStoreCustomerWithHttpInfo(storeId, customerId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update order
   * Update a specific order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {module:model/EcommerceOrder2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceOrder} and HTTP response
   */
  this.updateOrderWithHttpInfo = function(storeId, orderId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update order
   * Update a specific order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {module:model/EcommerceOrder2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceOrder}
   */
  this.updateOrder = function(storeId, orderId, body) {
    return this.updateOrderWithHttpInfo(storeId, orderId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update order line item
   * Update a specific order line item.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {String} lineId The id for the line item of an order.
   * @param {module:model/EcommerceOrderLineItem4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceOrderLineItem} and HTTP response
   */
  this.updateOrderLineItemWithHttpInfo = function(storeId, orderId, lineId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    // verify the required parameter 'lineId' is set
    if (lineId === undefined || lineId === null) {
      throw new Error("Missing the required parameter 'lineId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId,
      'line_id': lineId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}/lines/{line_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update order line item
   * Update a specific order line item.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {String} lineId The id for the line item of an order.
   * @param {module:model/EcommerceOrderLineItem4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceOrderLineItem}
   */
  this.updateOrderLineItem = function(storeId, orderId, lineId, body) {
    return this.updateOrderLineItemWithHttpInfo(storeId, orderId, lineId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update product
   * Update a specific product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {module:model/EcommerceProduct2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProduct} and HTTP response
   */
  this.updateStoreProductWithHttpInfo = function(storeId, productId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update product
   * Update a specific product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {module:model/EcommerceProduct2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProduct}
   */
  this.updateStoreProduct = function(storeId, productId, body) {
    return this.updateStoreProductWithHttpInfo(storeId, productId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update product image
   * Update a product image.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} imageId The id for the product image.
   * @param {module:model/EcommerceProductImage4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductImage} and HTTP response
   */
  this.updateProductImageWithHttpInfo = function(storeId, productId, imageId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'imageId' is set
    if (imageId === undefined || imageId === null) {
      throw new Error("Missing the required parameter 'imageId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId,
      'image_id': imageId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/images/{image_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update product image
   * Update a product image.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} imageId The id for the product image.
   * @param {module:model/EcommerceProductImage4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductImage}
   */
  this.updateProductImage = function(storeId, productId, imageId, body) {
    return this.updateProductImageWithHttpInfo(storeId, productId, imageId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update product variant
   * Update a product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @param {module:model/EcommerceProductVariant5} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductVariant} and HTTP response
   */
  this.updateProductVariantWithHttpInfo = function(storeId, productId, variantId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'variantId' is set
    if (variantId === undefined || variantId === null) {
      throw new Error("Missing the required parameter 'variantId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId,
      'variant_id': variantId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/variants/{variant_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update product variant
   * Update a product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @param {module:model/EcommerceProductVariant5} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductVariant}
   */
  this.updateProductVariant = function(storeId, productId, variantId, body) {
    return this.updateProductVariantWithHttpInfo(storeId, productId, variantId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update promo code
   * Update a promo code.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} promoCodeId The id for the promo code of a store.
   * @param {module:model/EcommercePromoCode2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommercePromoCode} and HTTP response
   */
  this.updatePromoCodeWithHttpInfo = function(storeId, promoRuleId, promoCodeId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    // verify the required parameter 'promoCodeId' is set
    if (promoCodeId === undefined || promoCodeId === null) {
      throw new Error("Missing the required parameter 'promoCodeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'promo_rule_id': promoRuleId,
      'promo_code_id': promoCodeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}/promo-codes/{promo_code_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update promo code
   * Update a promo code.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {String} promoCodeId The id for the promo code of a store.
   * @param {module:model/EcommercePromoCode2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommercePromoCode}
   */
  this.updatePromoCode = function(storeId, promoRuleId, promoCodeId, body) {
    return this.updatePromoCodeWithHttpInfo(storeId, promoRuleId, promoCodeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update promo rule
   * Update a promo rule.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {module:model/EcommercePromoRule2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommercePromoRule} and HTTP response
   */
  this.updatePromoRuleWithHttpInfo = function(storeId, promoRuleId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'promo_rule_id': promoRuleId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update promo rule
   * Update a promo rule.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {module:model/EcommercePromoRule2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommercePromoRule}
   */
  this.updatePromoRule = function(storeId, promoRuleId, body) {
    return this.updatePromoRuleWithHttpInfo(storeId, promoRuleId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add store
   * Add a new store to your Mailchimp account.
   * @param {module:model/EcommerceStore1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceStore} and HTTP response
   */
  this.addStoreWithHttpInfo = function(body) {
    var postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add store
   * Add a new store to your Mailchimp account.
   * @param {module:model/EcommerceStore1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceStore}
   */
  this.addStore = function(body) {
    return this.addStoreWithHttpInfo(body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add cart
   * Add a new cart to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceCart1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCart} and HTTP response
   */
  this.addStoreCartWithHttpInfo = function(storeId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add cart
   * Add a new cart to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceCart1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCart}
   */
  this.addStoreCart = function(storeId, body) {
    return this.addStoreCartWithHttpInfo(storeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add cart line item
   * Add a new line item to an existing cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {module:model/EcommerceCartLineItem3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCartLineItem} and HTTP response
   */
  this.addCartLineItemWithHttpInfo = function(storeId, cartId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'cartId' is set
    if (cartId === undefined || cartId === null) {
      throw new Error("Missing the required parameter 'cartId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'cart_id': cartId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/carts/{cart_id}/lines', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add cart line item
   * Add a new line item to an existing cart.
   * @param {String} storeId The store id.
   * @param {String} cartId The id for the cart.
   * @param {module:model/EcommerceCartLineItem3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCartLineItem}
   */
  this.addCartLineItem = function(storeId, cartId, body) {
    return this.addCartLineItemWithHttpInfo(storeId, cartId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add customer
   * Add a new customer to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceCustomer3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCustomer} and HTTP response
   */
  this.addStoreCustomerWithHttpInfo = function(storeId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/customers', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add customer
   * Add a new customer to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceCustomer3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCustomer}
   */
  this.addStoreCustomer = function(storeId, body) {
    return this.addStoreCustomerWithHttpInfo(storeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add order
   * Add a new order to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceOrder1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceOrder} and HTTP response
   */
  this.addStoreOrderWithHttpInfo = function(storeId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add order
   * Add a new order to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceOrder1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceOrder}
   */
  this.addStoreOrder = function(storeId, body) {
    return this.addStoreOrderWithHttpInfo(storeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add order line item
   * Add a new line item to an existing order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {module:model/EcommerceOrderLineItem3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceOrderLineItem} and HTTP response
   */
  this.addOrderLineItemWithHttpInfo = function(storeId, orderId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'orderId' is set
    if (orderId === undefined || orderId === null) {
      throw new Error("Missing the required parameter 'orderId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'order_id': orderId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/orders/{order_id}/lines', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add order line item
   * Add a new line item to an existing order.
   * @param {String} storeId The store id.
   * @param {String} orderId The id for the order in a store.
   * @param {module:model/EcommerceOrderLineItem3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceOrderLineItem}
   */
  this.addOrderLineItem = function(storeId, orderId, body) {
    return this.addOrderLineItemWithHttpInfo(storeId, orderId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add product
   * Add a new product to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceProduct1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProduct} and HTTP response
   */
  this.addStoreProductWithHttpInfo = function(storeId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add product
   * Add a new product to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommerceProduct1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProduct}
   */
  this.addStoreProduct = function(storeId, body) {
    return this.addStoreProductWithHttpInfo(storeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add product image
   * Add a new image to the product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {module:model/EcommerceProductImage3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductImage} and HTTP response
   */
  this.addProductImageWithHttpInfo = function(storeId, productId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/images', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add product image
   * Add a new image to the product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {module:model/EcommerceProductImage3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductImage}
   */
  this.addProductImage = function(storeId, productId, body) {
    return this.addProductImageWithHttpInfo(storeId, productId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add product variant
   * Add a new variant to the product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {module:model/EcommerceProductVariant3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductVariant} and HTTP response
   */
  this.addProductVariantsWithHttpInfo = function(storeId, productId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/variants', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add product variant
   * Add a new variant to the product.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {module:model/EcommerceProductVariant3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductVariant}
   */
  this.addProductVariants = function(storeId, productId, body) {
    return this.addProductVariantsWithHttpInfo(storeId, productId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add promo code
   * Add a new promo code to a store.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {module:model/EcommercePromoCode1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommercePromoCode} and HTTP response
   */
  this.addPromoCodeWithHttpInfo = function(storeId, promoRuleId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'promoRuleId' is set
    if (promoRuleId === undefined || promoRuleId === null) {
      throw new Error("Missing the required parameter 'promoRuleId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'promo_rule_id': promoRuleId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules/{promo_rule_id}/promo-codes', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add promo code
   * Add a new promo code to a store.
   * @param {String} storeId The store id.
   * @param {String} promoRuleId The id for the promo rule of a store.
   * @param {module:model/EcommercePromoCode1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommercePromoCode}
   */
  this.addPromoCode = function(storeId, promoRuleId, body) {
    return this.addPromoCodeWithHttpInfo(storeId, promoRuleId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add promo rule
   * Add a new promo rule to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommercePromoRule1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommercePromoRule} and HTTP response
   */
  this.addPromoRulesWithHttpInfo = function(storeId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/promo-rules', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add promo rule
   * Add a new promo rule to a store.
   * @param {String} storeId The store id.
   * @param {module:model/EcommercePromoRule1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommercePromoRule}
   */
  this.addPromoRules = function(storeId, body) {
    return this.addPromoRulesWithHttpInfo(storeId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add or update customer
   * Add or update a customer.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @param {module:model/EcommerceCustomer4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceCustomer} and HTTP response
   */
  this.setStoreCustomerWithHttpInfo = function(storeId, customerId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'customerId' is set
    if (customerId === undefined || customerId === null) {
      throw new Error("Missing the required parameter 'customerId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'customer_id': customerId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/customers/{customer_id}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add or update customer
   * Add or update a customer.
   * @param {String} storeId The store id.
   * @param {String} customerId The id for the customer of a store.
   * @param {module:model/EcommerceCustomer4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceCustomer}
   */
  this.setStoreCustomer = function(storeId, customerId, body) {
    return this.setStoreCustomerWithHttpInfo(storeId, customerId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add or update product variant
   * Add or update a product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @param {module:model/EcommerceProductVariant4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/EcommerceProductVariant} and HTTP response
   */
  this.addProductVariantWithHttpInfo = function(storeId, productId, variantId, body) {
    var postBody = body;

    // verify the required parameter 'storeId' is set
    if (storeId === undefined || storeId === null) {
      throw new Error("Missing the required parameter 'storeId' when calling ");
    }

    // verify the required parameter 'productId' is set
    if (productId === undefined || productId === null) {
      throw new Error("Missing the required parameter 'productId' when calling ");
    }

    // verify the required parameter 'variantId' is set
    if (variantId === undefined || variantId === null) {
      throw new Error("Missing the required parameter 'variantId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'store_id': storeId,
      'product_id': productId,
      'variant_id': variantId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/ecommerce/stores/{store_id}/products/{product_id}/variants/{variant_id}', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add or update product variant
   * Add or update a product variant.
   * @param {String} storeId The store id.
   * @param {String} productId The id for the product of a store.
   * @param {String} variantId The id for the product variant.
   * @param {module:model/EcommerceProductVariant4} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/EcommerceProductVariant}
   */
  this.addProductVariant = function(storeId, productId, variantId, body) {
    return this.addProductVariantWithHttpInfo(storeId, productId, variantId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }
};
