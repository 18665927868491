/*
 * Mailchimp Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 3.0.80
 * Contact: apihelp@mailchimp.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.12
 *
 * Do not edit the class manually.
 *
 */



var ApiClient = require('../ApiClient');

/**
 * Campaigns service.
 * @module api/CampaignsApi
 */

/**
 * Constructs a new CampaignsApi. 
 * @alias module:api/CampaignsApi
 * @class
 * @param {module:ApiClient} apiClient Optional API client implementation to use,
 * default to {@link module:ApiClient#instance} if unspecified.
 */
module.exports = function(apiClient) {
  this.apiClient = apiClient || ApiClient.instance;

  /**
   * Delete campaign
   * Remove a campaign from your Mailchimp account.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.removeWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete campaign
   * Remove a campaign from your Mailchimp account.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.remove = function(campaignId) {
    return this.removeWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Delete campaign feedback message
   * Remove a specific feedback message for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} feedbackId The unique id for the feedback message.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.deleteFeedbackMessageWithHttpInfo = function(campaignId, feedbackId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'feedbackId' is set
    if (feedbackId === undefined || feedbackId === null) {
      throw new Error("Missing the required parameter 'feedbackId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'feedback_id': feedbackId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/feedback/{feedback_id}', 'DELETE',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Delete campaign feedback message
   * Remove a specific feedback message for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} feedbackId The unique id for the feedback message.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.deleteFeedbackMessage = function(campaignId, feedbackId) {
    return this.deleteFeedbackMessageWithHttpInfo(campaignId, feedbackId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List campaigns
   * Get all campaigns in an account.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.type The campaign type.
   * @param {module:model/String} opts.status The status of the campaign.
   * @param {Date} opts.beforeSendTime Restrict the response to campaigns sent before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceSendTime Restrict the response to campaigns sent after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.beforeCreateTime Restrict the response to campaigns created before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceCreateTime Restrict the response to campaigns created after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.listId The unique id for the list.
   * @param {String} opts.folderId The unique folder id.
   * @param {String} opts.memberId Retrieve campaigns sent to a particular list member. Member ID is The MD5 hash of the lowercase version of the list member’s email address.
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2005} and HTTP response
   */
  this.listWithHttpInfo = function(opts) {
    opts = opts || {};
    var postBody = null;

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv'),
      'count': ( opts['count'] ? opts['count'] : opts['count']),
      'offset': ( opts['offset'] ? opts['offset'] : opts['offset']),
      'type': ( opts['type'] ? opts['type'] : opts['type']),
      'status': ( opts['status'] ? opts['status'] : opts['status']),
      'before_send_time': ( opts['beforeSendTime'] ? opts['beforeSendTime'] : opts['before_send_time']),
      'since_send_time': ( opts['sinceSendTime'] ? opts['sinceSendTime'] : opts['since_send_time']),
      'before_create_time': ( opts['beforeCreateTime'] ? opts['beforeCreateTime'] : opts['before_create_time']),
      'since_create_time': ( opts['sinceCreateTime'] ? opts['sinceCreateTime'] : opts['since_create_time']),
      'list_id': ( opts['listId'] ? opts['listId'] : opts['list_id']),
      'folder_id': ( opts['folderId'] ? opts['folderId'] : opts['folder_id']),
      'member_id': ( opts['memberId'] ? opts['memberId'] : opts['member_id']),
      'sort_field': ( opts['sortField'] ? opts['sortField'] : opts['sort_field']),
      'sort_dir': ( opts['sortDir'] ? opts['sortDir'] : opts['sort_dir'])
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaigns
   * Get all campaigns in an account.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @param {Number} opts.count The number of records to return. Default value is 10. Maximum value is 1000 (default to 10)
   * @param {Number} opts.offset Used for [pagination](https://mailchimp.com/developer/marketing/docs/methods-parameters/#pagination), this it the number of records from a collection to skip. Default value is 0. (default to 0)
   * @param {module:model/String} opts.type The campaign type.
   * @param {module:model/String} opts.status The status of the campaign.
   * @param {Date} opts.beforeSendTime Restrict the response to campaigns sent before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceSendTime Restrict the response to campaigns sent after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.beforeCreateTime Restrict the response to campaigns created before the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {Date} opts.sinceCreateTime Restrict the response to campaigns created after the set time. Uses ISO 8601 time format: 2015-10-21T15:41:36+00:00.
   * @param {String} opts.listId The unique id for the list.
   * @param {String} opts.folderId The unique folder id.
   * @param {String} opts.memberId Retrieve campaigns sent to a particular list member. Member ID is The MD5 hash of the lowercase version of the list member’s email address.
   * @param {module:model/String} opts.sortField Returns files sorted by the specified field.
   * @param {module:model/String} opts.sortDir Determines the order direction for sorted results.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2005}
   */
  this.list = function(opts) {
    return this.listWithHttpInfo(opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get campaign info
   * Get information about a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Campaign} and HTTP response
   */
  this.getWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get campaign info
   * Get information about a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Campaign}
   */
  this.get = function(campaignId, opts) {
    return this.getWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get campaign content
   * Get the the HTML and plain-text content for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignContent} and HTTP response
   */
  this.getContentWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/content', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get campaign content
   * Get the the HTML and plain-text content for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignContent}
   */
  this.getContent = function(campaignId, opts) {
    return this.getContentWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * List campaign feedback
   * Get team feedback while you're working together on a Mailchimp campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignReports} and HTTP response
   */
  this.getFeedbackWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/feedback', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * List campaign feedback
   * Get team feedback while you're working together on a Mailchimp campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignReports}
   */
  this.getFeedback = function(campaignId, opts) {
    return this.getFeedbackWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get campaign feedback message
   * Get a specific feedback message from a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} feedbackId The unique id for the feedback message.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignFeedback2} and HTTP response
   */
  this.getFeedbackMessageWithHttpInfo = function(campaignId, feedbackId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'feedbackId' is set
    if (feedbackId === undefined || feedbackId === null) {
      throw new Error("Missing the required parameter 'feedbackId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'feedback_id': feedbackId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/feedback/{feedback_id}', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get campaign feedback message
   * Get a specific feedback message from a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} feedbackId The unique id for the feedback message.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignFeedback2}
   */
  this.getFeedbackMessage = function(campaignId, feedbackId, opts) {
    return this.getFeedbackMessageWithHttpInfo(campaignId, feedbackId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Get campaign send checklist
   * Review the send checklist for a campaign, and resolve any issues before sending.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SendChecklist} and HTTP response
   */
  this.getSendChecklistWithHttpInfo = function(campaignId, opts) {
    opts = opts || {};
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
      'fields': this.apiClient.buildCollectionParam(opts['fields'] ? opts['fields'] : opts['fields'], 'csv'),
      'exclude_fields': this.apiClient.buildCollectionParam(opts['excludeFields'] ? opts['excludeFields'] : opts['exclude_fields'], 'csv')
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/send-checklist', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Get campaign send checklist
   * Review the send checklist for a campaign, and resolve any issues before sending.
   * @param {String} campaignId The unique id for the campaign.
   * @param {Object} opts Optional parameters
   * @param {Array.<String>} opts.fields A comma-separated list of fields to return. Reference parameters of sub-objects with dot notation.
   * @param {Array.<String>} opts.excludeFields A comma-separated list of fields to exclude. Reference parameters of sub-objects with dot notation.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SendChecklist}
   */
  this.getSendChecklist = function(campaignId, opts) {
    return this.getSendChecklistWithHttpInfo(campaignId, opts)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update campaign settings
   * Update some or all of the settings for a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/Campaign2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Campaign} and HTTP response
   */
  this.updateWithHttpInfo = function(campaignId, body) {
    var postBody = body;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update campaign settings
   * Update some or all of the settings for a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/Campaign2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Campaign}
   */
  this.update = function(campaignId, body) {
    return this.updateWithHttpInfo(campaignId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Update campaign feedback message
   * Update a specific feedback message for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} feedbackId The unique id for the feedback message.
   * @param {module:model/CampaignFeedback3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignFeedback2} and HTTP response
   */
  this.updateFeedbackMessageWithHttpInfo = function(campaignId, feedbackId, body) {
    var postBody = body;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'feedbackId' is set
    if (feedbackId === undefined || feedbackId === null) {
      throw new Error("Missing the required parameter 'feedbackId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId,
      'feedback_id': feedbackId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/feedback/{feedback_id}', 'PATCH',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Update campaign feedback message
   * Update a specific feedback message for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {String} feedbackId The unique id for the feedback message.
   * @param {module:model/CampaignFeedback3} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignFeedback2}
   */
  this.updateFeedbackMessage = function(campaignId, feedbackId, body) {
    return this.updateFeedbackMessageWithHttpInfo(campaignId, feedbackId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add campaign
   * Create a new Mailchimp campaign.
   * @param {module:model/Campaign1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Campaign} and HTTP response
   */
  this.createWithHttpInfo = function(body) {
    var postBody = body;

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add campaign
   * Create a new Mailchimp campaign.
   * @param {module:model/Campaign1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Campaign}
   */
  this.create = function(body) {
    return this.createWithHttpInfo(body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Cancel campaign
   * Cancel a Regular or Plain-Text Campaign after you send, before all of your recipients receive it. This feature is included with Mailchimp Pro.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.cancelSendWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/cancel-send', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Cancel campaign
   * Cancel a Regular or Plain-Text Campaign after you send, before all of your recipients receive it. This feature is included with Mailchimp Pro.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.cancelSend = function(campaignId) {
    return this.cancelSendWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Resend campaign
   * Creates a Resend to Non-Openers version of this campaign. We will also check if this campaign meets the criteria for Resend to Non-Openers campaigns.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Campaign3} and HTTP response
   */
  this.createResendWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/create-resend', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Resend campaign
   * Creates a Resend to Non-Openers version of this campaign. We will also check if this campaign meets the criteria for Resend to Non-Openers campaigns.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Campaign3}
   */
  this.createResend = function(campaignId) {
    return this.createResendWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Pause rss campaign
   * Pause an RSS-Driven campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.pauseWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/pause', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Pause rss campaign
   * Pause an RSS-Driven campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.pause = function(campaignId) {
    return this.pauseWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Replicate campaign
   * Replicate a campaign in saved or send status.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Campaign3} and HTTP response
   */
  this.replicateWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/replicate', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Replicate campaign
   * Replicate a campaign in saved or send status.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Campaign3}
   */
  this.replicate = function(campaignId) {
    return this.replicateWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Resume rss campaign
   * Resume an RSS-Driven campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.resumeWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/resume', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Resume rss campaign
   * Resume an RSS-Driven campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.resume = function(campaignId) {
    return this.resumeWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Schedule campaign
   * Schedule a campaign for delivery. If you're using Multivariate Campaigns to test send times or sending RSS Campaigns, use the send action instead.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/Body1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.scheduleWithHttpInfo = function(campaignId, body) {
    var postBody = body;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/schedule', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Schedule campaign
   * Schedule a campaign for delivery. If you're using Multivariate Campaigns to test send times or sending RSS Campaigns, use the send action instead.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/Body1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.schedule = function(campaignId, body) {
    return this.scheduleWithHttpInfo(campaignId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Send campaign
   * Send a Mailchimp campaign. For RSS Campaigns, the campaign will send according to its schedule. All other campaigns will send immediately.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.sendWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/send', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Send campaign
   * Send a Mailchimp campaign. For RSS Campaigns, the campaign will send according to its schedule. All other campaigns will send immediately.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.send = function(campaignId) {
    return this.sendWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Send test email
   * Send a test email.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/Body2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.sendTestEmailWithHttpInfo = function(campaignId, body) {
    var postBody = body;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/test', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Send test email
   * Send a test email.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/Body2} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.sendTestEmail = function(campaignId, body) {
    return this.sendTestEmailWithHttpInfo(campaignId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Unschedule campaign
   * Unschedule a scheduled campaign that hasn't started sending.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
   */
  this.unscheduleWithHttpInfo = function(campaignId) {
    var postBody = null;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/actions/unschedule', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Unschedule campaign
   * Unschedule a scheduled campaign that hasn't started sending.
   * @param {String} campaignId The unique id for the campaign.
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}
   */
  this.unschedule = function(campaignId) {
    return this.unscheduleWithHttpInfo(campaignId)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Add campaign feedback
   * Add feedback on a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/CampaignFeedback1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignFeedback2} and HTTP response
   */
  this.addFeedbackWithHttpInfo = function(campaignId, body) {
    var postBody = body;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/feedback', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Add campaign feedback
   * Add feedback on a specific campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/CampaignFeedback1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignFeedback2}
   */
  this.addFeedback = function(campaignId, body) {
    return this.addFeedbackWithHttpInfo(campaignId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }

  /**
   * Set campaign content
   * Set the content for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/CampaignContent1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CampaignContent} and HTTP response
   */
  this.setContentWithHttpInfo = function(campaignId, body) {
    var postBody = body;

    // verify the required parameter 'campaignId' is set
    if (campaignId === undefined || campaignId === null) {
      throw new Error("Missing the required parameter 'campaignId' when calling ");
    }

    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling ");
    }

    var pathParams = {
      'campaign_id': campaignId
    };
    
    /*
     The following block building queryParams is going to look a little odd, we're going look for values in $opts with both
     camelCase and snake_case format (which will be the same if singular word). This is because the API docs use snake_case
     but early versions of these SDKs used camelCase. So to be backward compatible we'll use both. 
    */
    var queryParams = {
    };
    var headerParams = {
    };

    var formParams = {
    };

    var authNames = ['basicAuth'];
    var contentTypes = ['application/json'];
    var accepts = ['application/json', 'application/problem+json'];
    var returnType = 'application/json';

    return this.apiClient.callApi(
      '/campaigns/{campaign_id}/content', 'PUT',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType
    );
  }
  /**
   * Set campaign content
   * Set the content for a campaign.
   * @param {String} campaignId The unique id for the campaign.
   * @param {module:model/CampaignContent1} body 
   * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CampaignContent}
   */
  this.setContent = function(campaignId, body) {
    return this.setContentWithHttpInfo(campaignId, body)
      .then(function(response_and_data) {
        return response_and_data.data;
      });
  }
};
